import { styled } from "@mui/material";
import IconButton from "@common/Buttons/IconButton";

export const MainPlayerControlsContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    position: "absolute",
    left: "calc(50% - 24px)",
  },
  [theme.breakpoints.up("md")]: {
    left: "calc(50% - 30px)",
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: "50%",

  [theme.breakpoints.up("md")]: {
    width: 60,
    height: 60,

    "div > svg": {
      width: 30,
      height: 30,
    },
  },
}));
