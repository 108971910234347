import { styled } from "@mui/material";
import * as StyledInfiniteCollection from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";

export const SearchContainer = styled("div")(({ theme }) => ({
  [`${StyledInfiniteCollection.InfiniteCollectionContainer}`]: {
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
  },
}));
