import { useEffect } from "react";
import Image from "next/image";
import Tealium from "@4tn/webx-analytics";
import { PrimaryButton } from "@common/Buttons";
import PageContainer from "@pageContent/common/PageContainer/PageContainer.styled";
import { TO_THE_HOME_PAGE, errorFallback } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import { noticeError } from "@utils/common/newRelic";
import usePageView from "@utils/common/usePageView";
import NotFoundSuggestions from "./NotFoundSuggestions";
import * as Styled from "./OopsPage.styled";

const trackClick = () => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK,
    event_category: TEALIUM_EVENT_CATEGORY.NOT_FOUND,
    event_label: TEALIUM_EVENT_LABEL.NAVIGATION.HOME,
  });
};

const OopsPage: React.FC<{ is404?: boolean; error?: unknown }> = ({ is404, error }) => {
  const { notFoundPage, errorPage } = useHostConfig();

  useEffect(() => {
    if (error) noticeError(error as Error);
  }, [error]);

  usePageView({ isLoading: false, data: null });

  const details = is404 ? notFoundPage : errorPage || errorFallback;

  if (!details) return;

  const { headerText, bodyText, imageUrl } = details;
  return (
    <PageContainer>
      <Styled.ErrorWrapper>
        {imageUrl && (
          <Styled.ImageContainer>
            <Styled.AspectRatio aspectRatio="3:2">
              <Image src={imageUrl} alt="error-image" fill sizes="(max-width: 1200px) 100vw, 50vw" />
            </Styled.AspectRatio>
          </Styled.ImageContainer>
        )}
        <Styled.TextContainer>
          <Styled.HeaderText>{headerText}</Styled.HeaderText>
          <Styled.DescriptionText>{bodyText}</Styled.DescriptionText>
          <PrimaryButton component="a" fullWidth href={"/"} onClick={trackClick}>
            {TO_THE_HOME_PAGE}
          </PrimaryButton>
        </Styled.TextContainer>
      </Styled.ErrorWrapper>

      {is404 && <NotFoundSuggestions />}
    </PageContainer>
  );
};

export default OopsPage;
