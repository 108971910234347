import dynamic from "next/dynamic";
import { SvgIconProps } from "@mui/material";
import { OutletNameEnum } from "@constants/consts";
import { useHostConfig } from "@utils/common/getHostConfig";

const logoMap: Record<OutletNameEnum, React.ComponentType> = {
  [OutletNameEnum.SHOWNIEUWS]: dynamic(() => import("./Brands/ShownieuwsLogo")),
  [OutletNameEnum.VANDAAGINSIDE]: dynamic(() => import("./Brands/VandaagInsideLogo")),
  [OutletNameEnum.HARTVANNEDERLAND]: dynamic(() => import("./Brands/HartVanNederlandLogo")),
  [OutletNameEnum.RADIO10]: dynamic(() => import("./Brands/Radio10Logo")),
  [OutletNameEnum.SKYRADIO]: dynamic(() => import("./Brands/SkyRadioLogo")),
  [OutletNameEnum.RADIO538]: dynamic(() => import("./Brands/Radio538Logo")),
  [OutletNameEnum.KIJK]: dynamic(() => import("./Brands/KijkLogo")),
  [OutletNameEnum.NIEUWSVANDEDAG]: dynamic(() => import("./Brands/NieuwsVanDeDagLogo")),
};

interface BrandLogoProps extends SvgIconProps {}

const BrandLogo: React.FC<BrandLogoProps> = (props) => {
  const { outletName } = useHostConfig();

  const Logo = logoMap[outletName as OutletNameEnum] || logoMap[OutletNameEnum.SHOWNIEUWS];

  if (Logo) return <Logo {...props} />;
  return null;
};

export default BrandLogo;
