export enum OutletNameEnum {
  SHOWNIEUWS = "Shownieuws",
  VANDAAGINSIDE = "Vandaag Inside",
  HARTVANNEDERLAND = "Hart van Nederland",
  RADIO10 = "Radio10",
  SKYRADIO = "Sky Radio",
  RADIO538 = "Radio 538",
  KIJK = "Kijk",
  NIEUWSVANDEDAG = "Nieuws van de Dag",
}

export const CONSENT_COOKIE = "eupubconsent-v2";

export enum OS {
  IOS = "ios",
  ANDROID = "android",
}
export enum PLATFORM {
  APP = "app",
  WEB = "web",
  FACEBOOK = "facebook",
}

export enum DEVICE {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  TABLET = "tablet",
  SMARTTV = "smarttv",
}

export enum IconEnum {
  HAMBURGER = "hamburger",
  SEARCH = "search",
  QUOTE = "quote",
  OPTIONS = "options",
  PLAY_ICON_FILLED = "play_icon_filled",
  LEFT_CHEVRON = "left_chevron",
  RIGHT_CHEVRON = "right_chevron",
  LEFT_BOLD_CHEVRON = "left_bold_chevron",
  RIGHT_BOLD_CHEVRON = "right_bold_chevron",
  LEFT_DOUBLE_CHEVRON = "left_double_chevron",
  RIGHT_DOUBLE_CHEVRON = "right_double_chevron",
  LEFT_ARROW = "left_arrow",
  RIGHT_ARROW = "right_arrow",
  ANIMATED_ARROW = "animated_arrow",
  CROSS = "cross",
  AIRPLAY = "airplay",
  CALL = "call",
  CHAT = "chat",
  CHECK = "check",
  CHROMECAST = "chromecast",
  CLOSED_CAPTION = "closed_caption",
  FILTER = "filter",
  FULLSCREEN = "fullscreen",
  FULLSCREEN_EXIT = "fullscreen_exit",
  HEART = "heart",
  HOME = "home",
  ID_CARD = "id_card",
  INFO = "info",
  PAUSE = "pause",
  PAUSE_THICK = "pause_thick",
  PENCIL = "pencil",
  PLAY_OUTLINED = "play_outlined",
  PLAY_OUTLINED_FILLED = "play_icon_filled_outlined",
  PLAYBACK_SPEED = "playback_speed",
  PLUS = "plus",
  POPOUT = "popout",
  POPOUT_PLAYER = "popout_player",
  PROFILE = "profile",
  LOCK = "lock",
  SETTINGS = "settings",
  SHARE = "share",
  STOP_OUTLINED = "stop_outlined",
  STOP_FILLED = "stop_filled",
  TIME = "time",
  VOLUME_MAX = "volume_max",
  VOLUME_MIN = "volume_min",
  VOLUME_OFF = "volume_off",
  ARROW_DOWN = "arrow_down",
  ARROW_UP = "arrow_up",
  DOWN_CHEVRON = "down_chevron",
  UP_CHEVRON = "up_chevron",
  LINK = "link",
  STATIONS = "stations",
  VIDEO = "video",
  VIDEO_FILLED = "video_filled",
  LOGOUT = "logout",
  SPINNER = "spinner",
  PLAYLIST = "playlist",
  ACTIONS = "actions",

  // social icons
  TALPA_NETWORK = "talpa_network",
  FIGMA = "figma",
  INSTAGRAM = "instagram",
  WHATSAPP = "whatsapp",
  MAIL = "mail",
  FACEBOOK = "facebook",
  TIK_TOK = "tik_tok",
  TWITTER = "x",
  SPOTIFY = "spotify",
  YOUTUBE = "youtube",

  // platform
  WEB = "web",
  APPS = "apps",
  ANDROID = "android",
  IOS = "iOS",

  //apps download
  GOOGLE_PLAY = "google_play",
  APP_STORE = "app_store",
}

export const maxPageContentWidth = 1408;
export const maxPageContentWidthMd = 740;

export const audioDashboardHeightMd = 470;

export const buttonBorderRadiusDefaultValue = 26;
export const buttonFontStyleDefaultValue = "normal";

export const firstChildSelector = "&:first-of-type";
export const lastChildSelector = "&:last-of-type";
export const lastDirectChildDiv = " & > div:last-child";

export const searchInputPlaceholderText = "Zoeken";

export enum QueryParams {
  SEARCH_KEYWORD = "zoek",
  SEARCH_TAB = "tab",
  PAGE = "page",
  IN_APP = "inApp",
}

export const footerNewsletterAction = "Aanmelden";
export const footerTalpaRightsText = "Talpa Network. Alle rechten voorbehouden. Geen tekst- en datamining.";
export const footerQueryKey = "footer";

export const contentTypes = ["navigation", "article", "page", "category"];

export const videoCategoryCollectionPageSize = 15;
export const playsLabel = "SPEELT AF";

export const tableNames = {
  article: "webx-platform-article-table",
  page: "webx-platform-page-table",
  navigation: "webx-platform-navigation-table",
  category: "webx-platform-category-table",
  audio: "webx-platform-audio-table",
  sponsoredCard: "webx-platform-sponsored-card-table",
  campaign: "webx-platform-campaign-table",
  redirect: "webx-platform-redirect-table",
};

export const seasonCollectionTitle = "Uitzendingen";

export const episodeUrlPrefix = "uitzendingen";

export const noResultText =
  "Er zijn geen afleveringen beschikbaar voor dit seizoen. Kom later terug of bekijk andere seizoenen.";

export const errorFallback = {
  headerText: "Oeps... er is iets misgegaan",
  bodyText:
    "Helaas is er een probleem op onze server opgetreden. We werken eraan om dit zo snel mogelijk op te lossen. Probeer het later nog eens.",
};

export const SHOW_MORE = "Toon meer";
export const TO_THE_HOME_PAGE = "Naar de homepage";
export const SIDEBAR_MENU_BACK_BUTTON_LABEL = "Terug";

export const COPIED_LINK_TEXT = "Link gekopieerd";
export const NO_CATEGORY_ITEM = "Helaas kunnen we geen CATEGORY artikelen vinden.";

export const SUBSCRIBE = "Aanmelden";
export const ENTER_YOUR_EMAIL_ADDRESS = "Vul hier je e-mailadres in";
export const YOU_ARE_ALMOST_THERE = "Je bent er bijna!";
export const newsletterErrorMessages = {
  EMAIL_INVALID: "Voer een geldig e-mailadres in",
  FETCH_ERROR: "Er is iets misgegaan, probeer het later nog een keer.",
};
export const SHOW_MORE_SEARCH_RESULTS = "Toon meer zoekresultaten";
export const RESULTS_FOR = "resultaten voor";

export const AD = "Advertentie";
export const CONTENT_LANGUAGE = "nl-NL";

export const SCALE_ANIMATION = {
  img: {
    objectFit: "cover",
    transition: "scale 0.4s ease-in-out",
  },

  "&:hover": {
    img: {
      scale: "1.1",
    },
  },
};

export const DPG_PROFILE: { [key: string]: string } = {
  Shownieuws: "shownieuws",
  "Vandaag Inside": "vivandaag",
  "Hart van Nederland": "hartvannederland",
  "Sky Radio": "skyradio",
  "Radio 538": "radio538",
  Radio10: "radio10",
};

export const AD_IDS = {
  topBanner: "h1",
  contentBanner: "r1",
  bottomBanner: "r2",
  articlePanelBanner: "r3",
};

export enum AvailabilityZone {
  NL = "NL",
  WW = "WW",
}

export enum CountryCode {
  NL = "NL",
}

export enum ScreenSizes {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export const isClient = typeof window !== "undefined";

export enum ViewType {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export const ROUTE_PATHS = {
  ACCOUNT: "/account",
  ARTICLE: "/artikelen/",
  DYNAMIC: /^\/[\w-]+$/,
  CATEGORY: {
    ARTICLES: "/artikelen",
    VIDEOS: "/videos",
  },
  HOME_PAGE: "/",
  VIDEO: {
    EPISODES: "/uitzendingen/",
    VIDEOS: "/videos/",
  },
  SEARCH: QueryParams.SEARCH_KEYWORD,
  PLAYLIST: "/playlist/",
  STATIONS: "/zenders/",
  SHOWS: "/shows/",
  CAMPAIGN: "/acties/",
  POPOUT: "/luister/",
  SCHEDULE: "/programmering",
};

export const platformFontFace = "platform-font-face";
export const HOME_PAGE_ID = "home";

export enum CONTENT_TYPE_LABEL {
  ARTICLE = "Artikel",
  VIDEO = "Video",
}

export enum Match {
  EXACT = "exact",
  SUBSTRING = "substring",
  DIFFERENT = "different",
  NOSUBSTRING = "nosubstring",
}

export const transitions = {
  hamburgerMenu: "transform 300ms ease-in-out",
};

export const LOGIN_FAILED = "Inloggen mislukt!";
export const LOGIN_TRY_AGAIN_TITLE = "Oeps, er ging iets fout met het inloggen. Kan je het nog eens proberen?";
export const LOGIN_TRY_AGAIN_BUTTON_TEXT = "Probeer opnieuw";

export const tenSeconds = 10 * 1000;
export const tenMinutes = 10 * 60 * 1000;
export const fifteenMinutes = 15 * 60 * 1000;

export const NOW = "Nu";
export const NOW_LIVE = "Nu Live";

export const radioProfile = "radio-brand-web";

export const trackHistory = "Afgespeelde nummers";
export const APPSYNC_HOST = "appsync.talparad.io";

export const listenBack = "Luister terug";

export enum TrackMediaTypeEnum {
  FRAGMENT = "luister-fragment",
  SPOTIFY = "luister-in-spotify",
}

export enum AudioType {
  STATION = "station",
  TRACK = "track",
  SHOW = "show",
  PLAYOUT = "playout",
}

export enum AudioPlayerType {
  TRITON = "triton",
  WEB = "web",
  VIDEO = "video",
}

export enum AudioPlayerStateEnum {
  STOP = "stop",
  LOADING = "loading",
  PLAYING = "playing",
  PAUSE = "pause",
  COMPLETED = "completed",
  FAILED = "failed",
}

export enum CampaignState {
  ACTIVE = "active",
  NOT_STARTED = "did_not_start_yet",
  EXPIRED = "expired",
}

export type AudioPlayerStateType = (typeof AudioPlayerStateEnum)[keyof typeof AudioPlayerStateEnum];

export const LOGIN = "Inloggen";
export const LOGOUT = "Uitloggen";
export const MANAGE_ACCOUNT = "Account beheren";

export const audioIconLabels = {
  VIDEO_ICON: "Kijk live",
  WHATSAPP_ICON: "Stuur bericht",
  VOLUME_ICON: "Volume",
  POP_OUT_ICON: "Open in venster",
  PLAYLIST: "Playlist",
};

export const NavigationItemType = {
  SUBMENU: "submenu",
  LINK: undefined,
} as const;

export const radioLiveVideoContainerIds = {
  AUDIO_DASHBOARD: "dashboard-video-container",
  STICKY_PLAYER: "sticky-player-video-container",
};

export const PAGE_CONTAINER = "page-container";

export const VIDEO_PORTAL_ID = "video-portal";

export const outletSwitchLabel = "Outlet Switch";
export const removeSwitchLabel = "Remove Switch";
export const cancelLabel = "Cancel";

export const ACTIVE_CAMPAIGN = "Doe mee!";
export const EXPIRED_CAMPAIGN = "Verlopen";
export const SHARE_THIS_CAMPAIGN = "Deel deze actie";
export const EXPIRED_CAMPAIGN_MESSAGE = "Deze actie is verlopen";
export const NOT_STARTED_CAMPAIGN_MESSAGE = "Deze actie is nog niet begonnen";

export const aspectRatios = {
  "16:9": "56.25%",
  "1:1": "100%",
  "3:2": "66.66%",
  "2:3": "150%",
  "4:3": "75%",
};

export const APP_STORE_DOWNLOAD = "Download in de App store";
export const GOOGLE_PLAY_DOWNLOAD = "Download in de Play store";
