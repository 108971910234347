import { styled } from "@mui/material";
import {
  PlaylistIcon,
  PopoutIcon,
  VideoIcon,
  VideoIconFilled,
  VolumeMinIcon,
  VolumeOffIcon,
  WhatsAppIcon,
} from "@common/Icons";
import { audioIconLabels } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";

export const IconSectionContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "inAudioDashboard",
})<{
  inAudioDashboard?: boolean;
}>(({ theme, inAudioDashboard }) => ({
  position: "relative",
  top: 12, // We are offseting the icon hover pseudo elements in order to keep the icons centered on the y-axis
  display: inAudioDashboard ? "flex" : "none",
  gap: theme.spacing(6),

  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

export const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(0, 2.5),
  minHeight: 47,
  justifyContent: "space-between",

  [`&:has(${StyledPopOutIcon})`]: {
    display: "none",
  },
  ["span"]: {
    font: fonts.bodyExtraSmall,
    color: colors.neutral0,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },

  [theme.breakpoints.up("md")]: {
    display: "flex",
    margin: theme.spacing(0, 2.5),
    [`&:has(${StyledPlaylistIcon})`]: {
      display: "none",
    },
    [`&:has(${StyledPopOutIcon})`]: {
      display: "flex",
    },
  },
}));

export const IconLabel = styled("span")(({ theme }) => ({
  display: "inline-block",
  font: fonts.bodyExtraSmall,
  color: colors.neutral0,
  textAlign: "center",
  marginTop: theme.spacing(2),
  whiteSpace: "nowrap",
}));

const sharedIconStyles = {
  cursor: "pointer",
  svg: {
    display: "flex",
    width: 24,
    height: 24,
    color: colors.neutral0,
  },
};

const sharedPseudoIconElementStyles = {
  "&:after": {
    content: "'•'",
    position: "relative",
    top: -5,
    left: 10,
    color: "transparent",
    fontFamily: "Tahoma", // So we can have uniform font-family styles across all outlets
    fontSize: 12,
  },
  "@media (hover: hover)": {
    "&:hover": {
      "&:after": {
        color: colors.neutral0,
      },
    },
  },
};

export const StyledVideoIcon = styled(VideoIcon)(({ theme }) => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
  height: "fit-content",
  "&:before": {
    visibility: "hidden",
    content: `'${audioIconLabels.VIDEO_ICON}'`,
    padding: theme.spacing(0.5, 3),
    background: colors.neutral90,
    borderRadius: 14,
    position: "absolute",
    top: -42,
    left: -29,
    color: colors.neutral0,
    fontFamily: fonts.bodySmallStrong,
  },
  "@media (hover: hover)": {
    "&:hover": {
      "&:before": {
        visibility: "visible",
      },
      "&:after": {
        color: colors.neutral0,
      },
    },
  },
}));

export const StyledWhatsAppIcon = styled(WhatsAppIcon)(() => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const StyledPlaylistIcon = styled(PlaylistIcon)(() => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
  height: 24,
}));

export const VolumeIcon = styled(VolumeMinIcon)(() => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const StyledVideoIconFilled = styled(VideoIconFilled, {
  shouldForwardProp: (prop) => prop !== "isVolumeMuted",
})<{
  isVideoPlaying: boolean;
}>(({ isVideoPlaying }) => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
  display: isVideoPlaying ? "block" : "none",
}));

export const VolumeMutedIcon = styled(VolumeOffIcon, { shouldForwardProp: (prop) => prop !== "isVolumeMuted" })<{
  isVolumeMuted: boolean;
}>(({ isVolumeMuted }) => ({
  display: isVolumeMuted ? "block" : "none", // Prevents icon section from "jumping" on the first mute by prerendering the icon
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const StyledPopOutIcon = styled(PopoutIcon)(({ theme }) => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
  [theme.breakpoints.only("sm")]: {
    display: "none",
  },

  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

export const VolumeControlsContainer = styled("div")<{ volumePercentage: string }>(({ theme, volumePercentage }) => ({
  display: "flex",
  gap: theme.spacing(1),
  maxHeight: 24,
  cursor: "pointer",

  [`${VolumeBarContainer}`]: {
    [`${VolumeBar}`]: {
      "&::-webkit-slider-runnable-track": {
        ...volumeBarPseudoTrackElementSharedStyles,
        WebkitAppearance: "none",
        background: `linear-gradient(to right, ${colors.neutral0} 0% ${volumePercentage}, rgba(242, 242, 242, 0.6) ${volumePercentage} 100%)`,
      },
      "&::-webkit-slider-thumb": {
        ...volumeBarPseudoThumbElementSharedStyles,
        WebkitAppearance: "none",
        marginTop: -4,
      },

      "&::-moz-range-track": {
        ...volumeBarPseudoTrackElementSharedStyles,
        background: `linear-gradient(to right, ${colors.neutral0} 0% ${volumePercentage}, rgba(242, 242, 242, 0.6) ${volumePercentage} 100%)`,
      },
      "&::-moz-range-thumb": {
        ...volumeBarPseudoThumbElementSharedStyles,
      },
    },
  },

  "&:hover": {
    [`${VolumeBarContainer}`]: {
      [`${VolumeBar}`]: {
        "&::-webkit-slider-runnable-track": {
          background: `linear-gradient(to right, ${colors.secondary} 0% ${volumePercentage}, rgba(242, 242, 242, 0.6) ${volumePercentage} 100%)`,
        },
        "&::-webkit-slider-thumb": {
          visibility: "visible",
        },

        "&::-moz-range-track": {
          background: `linear-gradient(to right, ${colors.secondary} 0% ${volumePercentage}, rgba(242, 242, 242, 0.6) ${volumePercentage} 100%)`,
        },
        "&::-moz-range-thumb": {
          visibility: "visible",
        },
      },
    },
  },
}));

const volumeBarPseudoTrackElementSharedStyles = {
  height: 4,
  borderRadius: 2,
};

const volumeBarPseudoThumbElementSharedStyles = {
  visibility: "hidden",
  height: 12,
  width: 12,
  borderRadius: "50%",
  border: "none",
  background: colors.neutral0,
};

export const VolumeBarContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const VolumeBar = styled("input")(() => ({
  WebkitAppearance: "none",
  background: "none",
  width: 72,
  cursor: "pointer",
}));
