import React from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import IconButton from "@common/Buttons/IconButton";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { SocialMediaEnum } from "@constants/socialMedia";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import * as Styled from "../Footer/Footer.styled";

type SocialShareItemType = {
  name: SocialMediaEnum;
  accessibilityLabel: string;
  icon: IconEnum;
  link?: string;
};

const trackClick = (eventCategory: string, eventLabel: string) => {
  Tealium.link({
    event_category: eventCategory,
    event_name: TEALIUM_EVENT_NAME.OUTBOUND_CLICK,
    event_label: eventLabel,
  });
};

const getSocialMediaItems = ({
  instagram,
  youtube,
  facebook,
  x,
}: Omit<SocialMedia, "title">): SocialShareItemType[] => [
  {
    name: SocialMediaEnum.INSTAGRAM,
    accessibilityLabel: "Instagram",
    icon: IconEnum.INSTAGRAM,
    link: instagram,
  },
  {
    name: SocialMediaEnum.YOUTUBE,
    accessibilityLabel: "Youtube",
    icon: IconEnum.YOUTUBE,
    link: youtube,
  },
  {
    name: SocialMediaEnum.FACEBOOK,
    accessibilityLabel: "Facebook",
    icon: IconEnum.FACEBOOK,
    link: facebook,
  },
  {
    name: SocialMediaEnum.X,
    accessibilityLabel: "X",
    icon: IconEnum.TWITTER,
    link: x,
  },
];

const SocialMedia: React.FC<SocialMedia> = ({
  title,
  instagram,
  youtube,
  facebook,
  x,
  eventCategory = TEALIUM_EVENT_CATEGORY.SOCIAL_MEDIA_LINK,
}) => {
  const socialMediaItems = getSocialMediaItems({ instagram, youtube, facebook, x });

  return (
    <Styled.IconsContainer>
      <Styled.IconsTitle>{title}</Styled.IconsTitle>
      <Styled.IconItemsContainer>
        {socialMediaItems.map((item) => (
          <React.Fragment key={item.name}>
            {item.link && (
              <Link href={item.link} target="_blank" onClick={() => item.link && trackClick(eventCategory, item.link)}>
                <IconButton
                  icon={item.icon}
                  size={BUTTON_SIZE.MEDIUM}
                  variant={VARIANT.PRIMARY}
                  color={colors.neutral0}
                  aria-label={item.accessibilityLabel}
                />
              </Link>
            )}
          </React.Fragment>
        ))}
      </Styled.IconItemsContainer>
    </Styled.IconsContainer>
  );
};

export default SocialMedia;
