import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import Duration from "@pageContent/common/Duration";
import Label from "@pageContent/common/Label";
import { colors, fonts } from "@constants/cssVariables";

export const SwimlaneItem = styled("div")(({ theme }) => ({
  flex: "0 0 40%",
  cursor: "pointer",
  [theme.breakpoints.up("md")]: {
    flex: "0 0 35%",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
  },
}));

export const ImageBorder = styled("div")<{ active: boolean }>(({ active, theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: 10,
  border: "3px solid transparent",
  [theme.breakpoints.up("md")]: {
    borderRadius: 12,
  },
  [theme.breakpoints.up("lg")]: {
    minWidth: 112,
    borderRadius: 9,
  },
  ...(active && { border: `3px solid ${colors.primary}` }),
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: 6,
  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
  },
  [theme.breakpoints.up("lg")]: {
    borderRadius: 6,
  },
  [theme.breakpoints.up("lg")]: {
    paddingBottom: "66.66%",
  },
}));

export const LabelContainer = styled(Label)({
  backgroundColor: colors.primary,
  right: 8,
  bottom: 8,
});

export const DurationContainer = styled(Duration)({
  backgroundColor: colors.primary,
  right: 8,
  bottom: 8,
});

export const SwimlaneItemTitle = styled("h3")(({ theme }) => ({
  font: fonts.header7,
  maxHeight: 60,
  margin: "3px 5px 0px 5px",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 3,
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-word",

  [theme.breakpoints.up("lg")]: {
    margin: "9px 16px 0px 7px",
  },
}));
