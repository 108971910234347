import Image from "next/image";
import SwimlaneItemSkeleton from "@pageContent/common/Skeletons/SwimlaneItemSkeleton";
import { IconEnum, playsLabel } from "@constants/consts";
import * as Styled from "./VideoCollectionSwimlaneItem.styled";

export const playIconTestId = "video-collection-play-icon";

interface VideoCollectionSwimlaneItemProps<T extends Video | VideoCollectionItem> {
  item: T;
  videoPosition: number;
  isMainVideo: boolean;
  onItemClick: (item: T, videoPosition: number) => void;
}

const VideoCollectionSwimlaneItem = <T extends Video | VideoCollectionItem>({
  item,
  videoPosition,
  isMainVideo,
  onItemClick,
}: VideoCollectionSwimlaneItemProps<T>) => {
  if (!item) {
    return <SwimlaneItemSkeleton />;
  }

  return (
    <Styled.SwimlaneItem key={item.videoGuid} onClick={() => onItemClick(item, videoPosition)}>
      <Styled.ImageBorder active={isMainVideo}>
        <Styled.AspectRatio aspectRatio="16:9">
          <Image src={item.imageUrl} alt={item.title} fill sizes="(max-width: 600px) 50vw, 250px" />
          {isMainVideo ? (
            <Styled.LabelContainer text={playsLabel} iconTestId={playIconTestId} />
          ) : (
            <Styled.DurationContainer
              icon={IconEnum.PLAY_ICON_FILLED}
              iconTestId={playIconTestId}
              duration={item.duration}
            />
          )}
        </Styled.AspectRatio>
      </Styled.ImageBorder>
      <Styled.SwimlaneItemTitle title={item.title}>{item.title}</Styled.SwimlaneItemTitle>
    </Styled.SwimlaneItem>
  );
};

export default VideoCollectionSwimlaneItem;
