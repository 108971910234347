import React, { ReactNode } from "react";
import { RichText } from "@constants/richText";
import * as Styled from "./RichTextHeading.styled";

export const headingMap: { [key: string]: string } = {
  [RichText.HEADING_2]: "2",
  [RichText.HEADING_3]: "3",
  [RichText.HEADING_4]: "4",
  [RichText.HEADING_5]: "5",
  [RichText.HEADING_6]: "6",
};

const RichTextHeading: React.FC<{ children: ReactNode; type?: string }> = ({ children, type }) => {
  if (!type) return null;

  const level = headingMap[type];

  return (
    <Styled.Heading as={`h${headingMap[type]}` as "h2" | "h3" | "h4" | "h5" | "h6"} level={level}>
      {children}
    </Styled.Heading>
  );
};

export default RichTextHeading;
