import { styled } from "@mui/material";
import Swimlane from "@pageContent/common/Swimlane";
import * as StyledSwimlane from "@pageContent/common/Swimlane/Swimlane.styled";
import { colors, fonts } from "@constants/cssVariables";

const firstChild = "& > div:first-of-type";
const swimlaneItemsSelector = "> div:last-child > div";

export const VideoCollectionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(6),
    margin: theme.spacing(10, 0),
  },
}));

export const VideoCollectionContainer = styled("div", { shouldForwardProp: (prop) => prop !== "hasSidebarVideoList" })<{
  hasSidebarVideoList?: boolean;
}>(({ theme, hasSidebarVideoList = true }) => ({
  display: "grid",
  position: "relative",
  gridTemplateColumns: "repeat(12, 1fr)",
  gap: theme.spacing(4),

  [`${firstChild}`]: {
    gridColumn: "span 12",
  },

  [theme.breakpoints.up("lg")]: {
    [`${firstChild}`]: {
      gridColumn: hasSidebarVideoList ? "span 8" : "span 12",
    },
  },
}));

export const SectionTitle = styled("h3")(() => ({
  font: fonts.header3,
}));

export const SwimlaneItems = styled(Swimlane, { shouldForwardProp: (prop) => prop !== "hasSidebarVideoList" })<{
  hasSidebarVideoList?: boolean;
}>(({ theme, hasSidebarVideoList = true }) => ({
  gridColumn: "span 12",

  ...(hasSidebarVideoList
    ? {
        [theme.breakpoints.up("lg")]: {
          position: "relative",
          overflowY: "auto",
          padding: theme.spacing(3.5, 0, 0, 3.5),
          borderRadius: 8,
          border: `2px solid ${colors.neutral10}`,
          gridColumn: "span 4",

          "> div": {
            marginBottom: theme.spacing(2),
            flexDirection: "column",
            position: "absolute",
            width: "calc(100% - 16px)",
          },

          "::-webkit-scrollbar": {
            width: "7px",
          },

          "::-webkit-scrollbar-thumb": {
            background: colors.neutral50,
            borderRadius: 8,
          },
          [`${StyledSwimlane.LeftArrow}, ${StyledSwimlane.RightArrow}, ${StyledSwimlane.LeftGradient}, ${StyledSwimlane.RightGradient}`]:
            {
              display: "none",
            },
        },
      }
    : {
        [swimlaneItemsSelector]: {
          display: "initial",
        },
      }),
}));
