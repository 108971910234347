import { BUTTON_SIZE, BUTTON_TYPE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import ButtonContainer from "../ButtonContainer";
import * as Styled from "./IconButton.styled";

interface IconButtonProps extends React.HTMLAttributes<HTMLElement> {
  component?: React.ElementType;
  disabled?: boolean;
  href?: string;
  icon: IconEnum;
  size: BUTTON_SIZE;
  variant: VARIANT;
  onClick?: React.MouseEventHandler;
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
  isNonInteractive?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({ component: Component = "button", variant, size, icon, ...props }) => {
  return (
    <ButtonContainer as={Component} buttonType={BUTTON_TYPE.ICON} variant={variant} size={size} {...props}>
      <Styled.StyledIcon icon={icon} variant={variant} size={size} />
    </ButtonContainer>
  );
};

export default IconButton;
