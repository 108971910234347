import { Skeleton, SxProps, Theme } from "@mui/material";
import { colors } from "@constants/cssVariables";
import * as Styled from "./TextSkeleton.styled";

export const textSkeletonTestId = "text-skeleton";

const TextSkeleton: React.FC<{
  width?: string | number | Array<string | number>;
  height?: string | number | Array<string | number>;
  sx?: SxProps<Theme>;
}> = ({ width, sx, height }) => (
  <Skeleton
    data-testid={textSkeletonTestId}
    sx={{ backgroundColor: colors.neutral10, width, height, ...sx }}
    variant="rounded"
    width="100"
  >
    <Styled.TextSkeletonWrapper />
  </Skeleton>
);

export default TextSkeleton;
