import { styled } from "@mui/material";

export const ArticleSkeletonWrapper = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const TextContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 3),
  gap: theme.spacing(2),
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",

  [theme.breakpoints.up("md")]: {
    width: "auto",
    alignItems: "flex-start",
    padding: theme.spacing(4),
  },
}));
