import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Tealium from "@4tn/webx-analytics";
import SocialMedia from "@navigation/SocialMedia";
import { useQuery } from "@tanstack/react-query";
import { NavigationItemType, SIDEBAR_MENU_BACK_BUTTON_LABEL } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import smoothScrollTo from "@utils/common/smoothScrollTo";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import * as Styled from "./Hamburger.styled";

const hamburgerQueryKey = "hamburger";

const trackItemClick = (title: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.MENU,
    event_name: TEALIUM_EVENT_NAME.MENU_CLICK,
    event_label: title,
  });
};

interface HamburgerMenuProps {
  navigation?: Navigation | null;
}

const Hamburger: React.FC<HamburgerMenuProps> = ({ navigation }) => {
  const { socialMedia } = useHostConfig();
  const divRef = useRef<HTMLDivElement>(null);
  const backButtonButtonRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const { data: items, error } = useQuery({
    queryKey: [hamburgerQueryKey],
    queryFn: () => fetchByEndpoint<Navigation>("/api/navigation/hamburger-menu"),
    refetchOnWindowFocus: false,
    initialData: navigation,
    enabled: !navigation,
  });

  useNoticeError(error, { queryKey: hamburgerQueryKey });

  const [menus, setMenus] = useState<Navigation[]>(items ? [items] : []);
  const timerRefs = useRef<NodeJS.Timeout[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleBackButton = (shouldShow = true) => {
    if (!backButtonButtonRef.current) return;
    backButtonButtonRef.current.style.visibility = shouldShow ? "visible" : "hidden";
  };

  const handleCloseClick = () => {
    window.eventBus.dispatch("hamburgerMenu", { isOpen: false });
  };

  const handleBackClick = () => {
    if (!divRef.current) return;
    if (menus.length === 2) {
      toggleBackButton(false);
    }
    const targetPosition = divRef.current.offsetWidth * (menus.length - 2);
    smoothScrollTo(divRef.current, targetPosition, 300);
    const timer = setTimeout(setMenus, 300, (prev) => (prev.length > 1 ? prev.slice(0, -1) : prev));

    timerRefs.current.push(timer);
  };

  const handleMenuClick = (item: Submenu | NavigationLink) => {
    if (item.type === NavigationItemType.SUBMENU) {
      toggleBackButton(true);
      setMenus((prev) =>
        [...prev, item].filter((item, index, array) => array.findIndex(({ id }) => id === item.id) === index)
      );
      return;
    }
    trackItemClick(item.title);
    if (item.target === "_blank") {
      window.open(item.url, "_blank");
      return;
    }
    router.push(item.url);
    handleCloseClick();
  };

  useEffect(() => {
    if (items) {
      setMenus([items]);
    }
  }, [items]);

  useEffect(() => {
    const onHamburgerMenu = (data: WebXEventParams["hamburgerMenu"]) => {
      setIsOpen(data.isOpen);
      document.body.classList.toggle("hamburger-menu-open", data.isOpen);
      if (data.isOpen) {
        document.body.style.setProperty("overflow-x", "hidden");
        document.documentElement.style.setProperty("overflow-x", "hidden");
      }

      if (data.isOpen === false && items) {
        toggleBackButton(false);
        document.documentElement.style.setProperty("overflow-x", "visible");
        const timer = setTimeout(() => {
          setMenus([items]);
          document.body.style.setProperty("overflow-x", "visible");
        }, 400);
        timerRefs.current.push(timer);
      }
    };

    window.eventBus.on("hamburgerMenu", onHamburgerMenu);
    return () => {
      window.eventBus.off("hamburgerMenu", onHamburgerMenu);
      timerRefs.current.forEach((timer) => clearTimeout(timer));
    };
  }, []);

  useEffect(() => {
    if (!divRef.current) return;
    const scrollToMenu = (event?: Event) => {
      if (!divRef.current) return;
      const targetPosition = divRef.current.offsetWidth * (menus.length - 1);
      smoothScrollTo(divRef.current, targetPosition, event ? 0 : 300);
    };
    scrollToMenu();
    if (!isOpen || menus.length === 1) return;

    window.addEventListener("resize", scrollToMenu);
    return () => {
      window.removeEventListener("resize", scrollToMenu);
    };
  }, [menus, divRef, isOpen]);

  if (!menus.length) return null;

  return (
    <Styled.SidebarMenu isHamburgerMenuOpen={isOpen}>
      <Styled.BackAndCloseContainer>
        <Styled.BackContainer ref={backButtonButtonRef} onClick={handleBackClick} role="button" aria-label="back">
          <Styled.Back />
          <Styled.BackText>{SIDEBAR_MENU_BACK_BUTTON_LABEL}</Styled.BackText>
        </Styled.BackContainer>
        <Styled.Close role="button" aria-label="close" onClick={handleCloseClick} />
      </Styled.BackAndCloseContainer>
      <Styled.AnimationContainer ref={divRef}>
        {menus.map((menu, index) => (
          <Styled.OptionsWrapper key={menu.id}>
            <Styled.Options level={index + 1}>
              {menu.items.map((item) => (
                <Styled.MenuItem role="menuitem" key={item.id} onClick={() => handleMenuClick(item)}>
                  <Styled.MenuItemTitle>{item.title}</Styled.MenuItemTitle>
                  {item.type === NavigationItemType.SUBMENU && <Styled.RightArrow />}
                </Styled.MenuItem>
              ))}
            </Styled.Options>
            {index === 0 && (
              <Styled.SocialMediaContainer>
                <SocialMedia {...socialMedia} />
              </Styled.SocialMediaContainer>
            )}
          </Styled.OptionsWrapper>
        ))}
      </Styled.AnimationContainer>
    </Styled.SidebarMenu>
  );
};

export default Hamburger;
