import { styled } from "@mui/material";
import Icon from "@common/Icons";
import { colors, fonts, opacities } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const LabelWrapper = styled("div")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.buttonSmall,
  backgroundColor: opacities.opacity02,
  borderRadius: 26,
  height: 22,
  padding: theme.spacing(1, 2),
  margin: 0,
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
}));

export const LabelIcon = styled(Icon)({
  svg: {
    color: colors.neutral0,
    width: 14,
    height: 14,
    zIndex: zIndex.baseControls,
  },
});
