import { Box, Skeleton } from "@mui/material";
import * as StyledInfiniteCollection from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton/TextSkeleton";
import * as StyledSwimlane from "@pageContent/common/Swimlane/Swimlane.styled";
import * as Styled from "./RadioSchedule.styled";

const RadioScheduleSkeleton: React.FC<{ hasShowAllText: boolean }> = ({ hasShowAllText }) => (
  <Styled.ContainerSkeleton>
    <StyledInfiniteCollection.TitleWrapper>
      <TextSkeleton height={[24, 24, 32]} width={[180, 180, 240]} />
      {hasShowAllText && <TextSkeleton height={16} width={[60, 60, 100]} />}
    </StyledInfiniteCollection.TitleWrapper>
    <Box sx={{ display: "flex", gap: 4, marginTop: 4, marginBottom: 4, width: "fit-content", overflow: "auto" }}>
      <StyledSwimlane.LeftGradient />
      <Styled.DividerSkeleton>
        <Skeleton variant="rounded" width="100%" height="100%" />
      </Styled.DividerSkeleton>
      {Array.from({ length: 5 }).map((_, i) => (
        <Styled.ItemContainerSkeleton key={`schedule-skeleton-${i}`}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Styled.ItemContainerSkeleton>
      ))}
      <StyledSwimlane.RightGradient />
    </Box>
  </Styled.ContainerSkeleton>
);

export default RadioScheduleSkeleton;
