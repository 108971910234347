import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import {
  AudioPlayerStateEnum,
  AudioPlayerStateType,
  AudioPlayerType,
  AudioType,
  radioLiveVideoContainerIds,
} from "@constants/consts";
import { Track } from "@utils/audio/dataModels";
import * as Styled from "./AudioPlayer.styled";
import audioPlayerManager from "./AudioPlayerManager/AudioPlayerManager";
import IconSection from "./components/IconSection";
import MainPlayerControls from "./components/MainPlayerControls";
import NowPlaying from "./components/NowPlaying";

const VideoPlayerPortal = dynamic(() => import("@video/VideoPlayerPortal"));

const AudioPlayer: React.FC<{ isOnHomePage: boolean; isOnStationPage: boolean; mainStation: AudioStationEntry }> = ({
  isOnHomePage,
  isOnStationPage,
  mainStation,
}) => {
  const [status, setStatus] = useState<{ state: AudioPlayerStateType; details: string }>({
    state: AudioPlayerStateEnum.STOP,
    details: "",
  });

  const [station, setStation] = useState(mainStation);
  const [inDashboardView, setInDashboardView] = useState(isOnHomePage || isOnStationPage);
  const [playerType, setPlayerType] = useState<AudioPlayerEventParams["playerType"]>(
    audioPlayerManager.getPlayerType()
  );

  const isVideoPlaying = playerType === AudioPlayerType.VIDEO;

  useEffect(() => {
    const handlePlay = ({ details }: AudioPlayerEventParams["play"]) => {
      setStatus({ state: AudioPlayerStateEnum.PLAYING, details });
    };

    const handlePause = ({ details }: AudioPlayerEventParams["pause"]) => {
      setStatus({ state: AudioPlayerStateEnum.PAUSE, details });
    };

    const handleStop = ({ details }: AudioPlayerEventParams["stop"]) => {
      setStatus({ state: AudioPlayerStateEnum.STOP, details });
    };

    const handleAudioCompleted = ({ details }: AudioPlayerEventParams["complete"]) => {
      setStatus({ state: AudioPlayerStateEnum.COMPLETED, details });
    };

    const handleError = ({ details }: AudioPlayerEventParams["error"]) => {
      setStatus({ state: AudioPlayerStateEnum.FAILED, details });
    };

    const handlePlayerTypeChange = (handlerPlayerType: AudioPlayerType) => {
      setPlayerType(handlerPlayerType);
    };

    const handleAd = ({ details }: AudioPlayerEventParams["adStarted"] | AudioPlayerEventParams["adComplete"]) => {
      setStatus((prevState) => ({ ...prevState, details }));
    };

    const handleBeforePlay = () => {
      setStatus({ state: AudioPlayerStateEnum.LOADING, details: "Player is loading" });
    };

    const handleAudioItem = (audioItem: AudioStationEntry | Track) => {
      if (audioItem.type === AudioType.STATION) {
        setStation(audioItem);
      }
    };
    const onDashboardInView = (data: WebXEventParams["audioDashboard"]) => setInDashboardView(data.visible);

    audioPlayerManager.on("play", handlePlay);
    audioPlayerManager.on("pause", handlePause);
    audioPlayerManager.on("adStarted", handleAd);
    audioPlayerManager.on("adComplete", handleAd);
    audioPlayerManager.on("stop", handleStop);
    audioPlayerManager.on("complete", handleAudioCompleted);
    audioPlayerManager.on("error", handleError);
    audioPlayerManager.on("playerType", handlePlayerTypeChange);
    audioPlayerManager.on("beforePlay", handleBeforePlay);
    audioPlayerManager.on("audioItem", handleAudioItem);
    window.eventBus.on("audioDashboard", onDashboardInView);

    return () => {
      audioPlayerManager.off("play", handlePlay);
      audioPlayerManager.off("pause", handlePause);
      audioPlayerManager.off("adStarted", handleAd);
      audioPlayerManager.off("adComplete", handleAd);
      audioPlayerManager.off("stop", handleStop);
      audioPlayerManager.off("complete", handleAudioCompleted);
      audioPlayerManager.off("error", handleError);
      audioPlayerManager.off("playerType", handlePlayerTypeChange);
      audioPlayerManager.off("beforePlay", handleBeforePlay);
      audioPlayerManager.off("audioItem", handleAudioItem);
      window.eventBus.off("audioDashboard", onDashboardInView);
    };
  }, []);

  useEffect(() => {
    if (station && !audioPlayerManager.media) {
      audioPlayerManager.load(station);
    }
  }, [station]);

  if (!station) {
    return null;
  }

  return (
    <Styled.AudioPlayerContainer inDashboardView={!!inDashboardView} id={radioLiveVideoContainerIds.STICKY_PLAYER}>
      <Styled.AudioPlayerContent>
        <NowPlaying
          station={station}
          playerType={playerType}
          isCurrentStationLoaded={playerType === AudioPlayerType.TRITON}
          alternateNowPlayingDetails
        />
        <MainPlayerControls station={station} playbackState={status.state} playerType={playerType} />
        <IconSection station={station} isVideoPlaying={isVideoPlaying} />
        {isVideoPlaying && mainStation.videoGuid && (
          <VideoPlayerPortal mainStation={mainStation} inDashboardView={inDashboardView} />
        )}
      </Styled.AudioPlayerContent>
    </Styled.AudioPlayerContainer>
  );
};

export default AudioPlayer;
