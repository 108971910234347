import React, { ReactNode } from "react";
import { RichText } from "@constants/richText";
import * as Styled from "./RichTextList.styled";

const RichTextList: React.FC<{ children: ReactNode; type?: string }> = ({ children, type }) => {
  if (type === RichText.OL_LIST) {
    return <Styled.OrderedList>{children}</Styled.OrderedList>;
  }

  if (type === RichText.UL_LIST) {
    return <Styled.UnorderedList>{children}</Styled.UnorderedList>;
  }

  return null;
};

export default RichTextList;
