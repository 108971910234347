import React from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import { ReactSVG } from "react-svg";
import { APP_STORE_DOWNLOAD, GOOGLE_PLAY_DOWNLOAD } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import * as Styled from "../Footer/Footer.styled";

const trackClick = (eventLabel: string, contentName: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.FOOTER,
    event_name: TEALIUM_EVENT_NAME.OUTBOUND_CLICK,
    event_label: eventLabel,
    content_name: contentName,
  });
};

const APP_LINKS = [
  {
    imageSrc: "/images/app_store.svg",
    name: APP_STORE_DOWNLOAD,
  },
  {
    imageSrc: "/images/google_play.svg",
    name: GOOGLE_PLAY_DOWNLOAD,
  },
];

const AppDownload: React.FC<AppDownload> = ({ title, adjustLink }) => {
  return (
    <Styled.IconsContainer isDownloadAppIcon>
      <Styled.IconsTitle>{title}</Styled.IconsTitle>
      <Styled.IconItemsContainer isDownloadAppIcon>
        {APP_LINKS.map(({ imageSrc, name }) => (
          <Link
            key={name}
            href={adjustLink}
            target="_blank"
            aria-label={name}
            onClick={() => trackClick(adjustLink, name)}
          >
            <ReactSVG src={imageSrc} />
          </Link>
        ))}
      </Styled.IconItemsContainer>
    </Styled.IconsContainer>
  );
};

export default AppDownload;
