import PageContainer from "@pageContent/common/PageContainer";
import GalleryHeaderSkeleton from "../GalleryHeaderSkeleton/GalleryHeaderSkeleton";
import NumericCollectionSkeleton from "../NumericCollectionSkeleton/NumericCollectionSkeleton";

const DynamicPageSkeleton: React.FC = () => (
  <PageContainer>
    <GalleryHeaderSkeleton />
    <NumericCollectionSkeleton />
  </PageContainer>
);

export default DynamicPageSkeleton;
