import getConfig from "next/config";
import { AudioMonitor, HeartBeat } from "@4tn/webx-analytics";
import { AudioPlayerType, AudioType } from "@constants/consts";
import { Show, Track } from "@utils/audio/dataModels";
import getHostConfig from "@utils/common/getHostConfig";
import TritonAudioPlayer from "./TritonAudioPlayer";
import WebAudioPlayer from "./WebAudioPlayer";

const {
  publicRuntimeConfig: { version },
} = getConfig();

class AudioPlayerManager {
  private webPlayer: WebAudioPlayer;
  private tritonPlayer: TritonAudioPlayer;
  private player: WebAudioPlayer | TritonAudioPlayer | null = null;
  private eventCallbacks: PlayerEventRegistry<keyof AudioPlayerEventParams> = {};
  public media: AudioStationEntry | Track | null = null;
  public isMuted: boolean = false;
  public volume: number = 1;

  constructor() {
    this.webPlayer = new WebAudioPlayer(this.dispatch, this.getVolume);
    this.tritonPlayer = new TritonAudioPlayer(this.dispatch, this.getVolume);

    if (typeof window !== "undefined") this.setupAudioMonitoring();
  }

  async load(media: AudioStationEntry | Track) {
    if (this.player?.isPlaying) {
      await this.player.stop();
    }

    this.media = media;

    if (media.type === AudioType.STATION) {
      this.setupPlayer(AudioPlayerType.TRITON);
      this.dispatch("audioItem", media);
      this.player?.load(media);
    } else if (media.type === AudioType.TRACK) {
      this.setupPlayer(AudioPlayerType.WEB);
      if (this.tritonPlayer) this.tritonPlayer.cleanUpSubscription();
      if (media.previewUrl) {
        this.start(media.previewUrl);
        this.dispatch("nowPlaying", media);
      }
    }
  }

  async start(source: string): Promise<void> {
    if (this.player?.isPlaying) {
      await this.player.stop();
    }

    this.dispatch("beforePlay", { details: "Attempting to play..." });
    if (this.player) {
      this.player.play(source);
    }
  }

  async switchVisualStream(shouldToggleVideo: boolean, media: AudioStationEntry): Promise<void> {
    if (!this.player) return;

    if (media && media.id !== this.media?.id) {
      if (shouldToggleVideo) {
        this.player.stop();
        this.media = media;
        this.player = this.tritonPlayer;
        this.dispatch("audioItem", media);
      } else {
        await this.load(media);
      }
    }

    this.dispatch("stop", { details: "" });

    this.player.switchVisualRadio(shouldToggleVideo);

    this.dispatch("playerType", this.player.type);
  }

  private setupPlayer(playerType: AudioPlayerType): void {
    if (playerType === AudioPlayerType.WEB) {
      this.player = this.webPlayer;
    } else {
      this.player = this.tritonPlayer;
    }

    this.dispatch("playerType", this.player.type);
  }

  private setupAudioMonitoring() {
    const { playerId, mainStationSlug } = getHostConfig();

    AudioMonitor.init({
      id: "audioPlayer",
      instance: this,
      defaultLayer: {
        player_channel: mainStationSlug,
        player_version: version,
        player_id: playerId,
        player_name: playerId,
        player_source: `${playerId}web`,
      },
    });

    HeartBeat.init({
      scheme: [
        { start: 0, interval: 1 * 60 }, // Send heartbeat every minute
      ],
      events: {
        onHeartBeat: (event) => {
          const currentMinute = event.currentHeartBeat / 60;
          const allowedIntervals = [1, 8, 15, 30, 60, 90, 120];
          // Send heartbeat if it's one of the milestones or a multiple of 120 minutes
          if (allowedIntervals.includes(currentMinute) || currentMinute % 120 === 0) {
            const trackData = AudioMonitor.getTrackData();
            return { ...trackData, event_label: `${currentMinute}`, event_value: null };
          }

          return false;
        },
      },
    });
  }

  private dispatch = <K extends keyof AudioPlayerEventParams>(key: K, params: AudioPlayerEventParams[K]): void => {
    if (key === "complete" && this.tritonPlayer.currentStation) {
      // load previous station
      this.load(this.tritonPlayer.currentStation);
    }

    if (key === "play") {
      HeartBeat.start();
    }

    if (["complete", "error", "stop", "pause"].includes(key)) {
      HeartBeat.stop();
    }

    const callbacks = (this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[]) || [];

    callbacks.forEach((callback) => {
      callback(params);
    });
  };

  on<K extends keyof AudioPlayerEventParams>(key: K, callback: (params: AudioPlayerEventParams[K]) => void): void {
    if (!this.eventCallbacks[key]) {
      this.eventCallbacks[key] = [];
    }
    const callbacks = this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[];
    if (!callbacks.includes(callback)) {
      callbacks.push(callback);
    }
  }

  off<K extends keyof AudioPlayerEventParams>(key: K, callback: (params: AudioPlayerEventParams[K]) => void): void {
    const callbacks = this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[];
    const index = callbacks?.indexOf(callback);
    if (callbacks && index && index !== -1) {
      callbacks.splice(index, 1);
    }
  }

  pause(): void {
    if (this.player) {
      this.player.pause();
    }
  }

  resume(): void {
    if (this.player) {
      this.player.resume();
    }
  }

  stop(): void {
    if (this.player) {
      this.player.stop();
    }
  }

  complete(): void {
    if (this.player) {
      this.player.complete();
    }
  }

  mute(): void {
    this.isMuted = true;
    if (this.player) {
      this.player.mute();
    }
  }

  unmute(): void {
    this.isMuted = false;
    if (this.player) {
      this.player.unmute();
    }
  }

  volumeChange = (volume: number): void => {
    if (this.isMuted) this.isMuted = false;
    this.volume = volume;
    if (this.player) {
      this.player.volumeChange(volume);
    }
  };

  getNowPlayingTrack(): Track | null {
    if (!this.player) return null;

    return this.player.nowPlayingTrack;
  }

  getNowPlayingShow(): Show | null {
    if (!this.player) return null;

    return this.player.nowPlayingShow;
  }

  getIsAudioPlaying(): boolean {
    return (
      !!this.player?.isPlaying &&
      (this.player.type === AudioPlayerType.TRITON || this.player.type === AudioPlayerType.WEB)
    );
  }

  getIsVideoPlaying(): boolean {
    return !!this.player?.isPlaying && this.player.type === AudioPlayerType.VIDEO;
  }

  getPlayerType(): AudioPlayerType | null {
    return this.player?.type || null;
  }

  getIsPlaying(): boolean {
    return this.getIsAudioPlaying() || this.getIsVideoPlaying();
  }

  getVolume = (): number => {
    if (this.isMuted) return 0;

    return this.volume;
  };
}

const audioPlayerManager = new AudioPlayerManager();

export default audioPlayerManager;
