import { Skeleton as MuiSkeleton, styled } from "@mui/material";
import { colors } from "@constants/cssVariables";

export const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
  width: 115,
  maxWidth: "none",
  backgroundColor: colors.neutral10,
  height: 76,

  [theme.breakpoints.up("sm")]: {
    width: "100%",
    height: "100%",
  },
}));
