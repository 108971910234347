import { styled } from "@mui/material";

export const ShadowDomContainer = styled("div")(() => ({
  transform: "scale(1)",

  ["::part(youtube-embed)"]: {
    maxWidth: "100%",

    "@supports (aspect-ratio: 16 / 9)": {
      aspectRatio: "16 / 9",
      width: "100%",
      height: "auto",
      maxWidth: "none",
    },
  },
}));
