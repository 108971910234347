import { FC, useState } from "react";
import Tealium, { JwMonitor } from "@4tn/webx-analytics";
import InlinePlayer from "@pageContent/ArticleInlinePlayer/InlinePlayer";
import { CONTENT_LANGUAGE } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import * as Styled from "./VideoCollection.styled";
import VideoCollectionSwimlaneItem from "./components/VideoCollectionSwimlaneItem";

export const trackVideoItemClick = ({
  title,
  videoPosition,
  eventLabel,
  videoGuid,
  slug,
  updatedAt,
  createdAt,
}: VideoCollectionItem & {
  eventLabel: string;
  videoPosition: number;
}) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_PLAY,
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_label: eventLabel,
    event_value: videoPosition,
    media_id: videoGuid,
    media_name: title,
    content_id: slug || null,
    content_datecreated: createdAt || null,
    content_datepublished: createdAt || null,
    content_datemodified: updatedAt || null,
    content_language: CONTENT_LANGUAGE,
  });
};

interface VideoCollectionProps {
  title: string;
  items: Array<VideoCollectionItem>;
  autoplay?: boolean;
  hasSidebarVideoList?: boolean;
}

const VideoCollection: FC<VideoCollectionProps> = ({ items, title, autoplay, hasSidebarVideoList = true }) => {
  const [mainVideo, setMainVideo] = useState<VideoCollectionItem>(items[0]);
  const [playVideo, setPlayVideo] = useState(false);

  const onSwimlaneItemClick = (item: VideoCollectionItem, videoPosition: number) => {
    JwMonitor.setPlayReason("interaction");
    trackVideoItemClick({ ...item, eventLabel: title, videoPosition });
    setMainVideo(item);
    setPlayVideo(true);
  };

  if (!items.length) {
    return null;
  }

  return (
    <Styled.VideoCollectionWrapper>
      <Styled.SectionTitle>{title}</Styled.SectionTitle>
      <Styled.VideoCollectionContainer hasSidebarVideoList={hasSidebarVideoList}>
        <InlinePlayer
          title={mainVideo.title}
          duration={mainVideo.duration}
          imageUrl={mainVideo.imageUrl}
          videoGuid={mainVideo.videoGuid}
          playerLoadedInitially={playVideo || autoplay}
        />
        <Styled.SwimlaneItems scrollToItemIndex={1} hasSidebarVideoList={hasSidebarVideoList}>
          {items.map((item, index) => (
            <VideoCollectionSwimlaneItem<VideoCollectionItem>
              key={item.id}
              videoPosition={index + 1}
              item={item}
              isMainVideo={item.id === mainVideo.id}
              onItemClick={onSwimlaneItemClick}
            />
          ))}
        </Styled.SwimlaneItems>
      </Styled.VideoCollectionContainer>
    </Styled.VideoCollectionWrapper>
  );
};

export default VideoCollection;
