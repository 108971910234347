import { styled } from "@mui/material";
import Icon from "@common/Icons";
import { BUTTON_SIZE, VARIANT, iconSizes, variantColors } from "@constants/button";

export const StyledIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== "as" && prop !== "variant" && prop !== "visibility",
})<{
  variant: VARIANT;
  size: BUTTON_SIZE;
}>(({ variant, size }) => ({
  height: iconSizes[size].height,
  width: iconSizes[size].width,
  svg: {
    display: "block",
    color: variantColors[variant].contentColor,
    height: iconSizes[size].height,
    width: iconSizes[size].width,
  },
}));

export const ButtonText = styled("p")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
