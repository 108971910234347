import { useEffect, useRef } from "react";
import ErrorBoundary from "@common/ErrorBoundary/ErrorBoundary";
import { HtmlEmbedNode } from "@typings/richText";
import * as Styled from "./HtmlEmbed.styled";

const HtmlEmbed: React.FC<HtmlEmbedNode["data"]> = ({ html }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rootRef.current) {
      if (html.includes("<script")) {
        const root = rootRef.current;
        root.innerHTML = html;

        const scriptTags = rootRef.current.querySelectorAll("script");
        const appendedScripts: HTMLScriptElement[] = [];

        scriptTags.forEach((script) => {
          const newScript = document.createElement("script");
          newScript.type = script.type || "text/javascript";
          if (script.src) {
            newScript.src = script.src;
          } else {
            newScript.textContent = script.textContent;
          }
          root.appendChild(newScript);
          appendedScripts.push(newScript);
          script.remove();
        });

        return () => {
          root.innerHTML = "";
          appendedScripts.forEach((script) => script.remove());
        };
      }

      const shadowRoot = rootRef.current.shadowRoot || rootRef.current.attachShadow({ mode: "open" });
      shadowRoot.innerHTML = html;
    }
  }, [html]);

  return <Styled.ShadowDomContainer ref={rootRef} />;
};

const SafeHtmlEmbed: React.FC<HtmlEmbedNode["data"]> = (props) => (
  <ErrorBoundary onError={() => <></>}>
    <HtmlEmbed {...props} />
  </ErrorBoundary>
);

export default SafeHtmlEmbed;
