import styled from "@emotion/styled";
import { aspectRatios } from "@constants/consts";
import { colors } from "@constants/cssVariables";

const AspectRatioContainer = styled("div", { shouldForwardProp: (prop) => prop !== "aspectRatio" })<{
  aspectRatio: "16:9" | "1:1" | "2:3" | "3:2" | "4:3";
}>(({ aspectRatio }) => ({
  width: "100%",
  position: "relative",
  backgroundColor: colors.neutral10,
  paddingBottom: aspectRatios[aspectRatio],
  height: 0,
}));

export default AspectRatioContainer;
