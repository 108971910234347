import * as Styled from "./HighlightedQuote.styled";

interface HighlightedQuoteProps {
  quote: string;
  author?: string;
}

const HighlightedQuote: React.FC<HighlightedQuoteProps> = ({ quote, author }) => {
  return (
    <Styled.HighlightedQuoteWrapper>
      <Styled.HighlightedQuoteIcon />
      <Styled.Quote>{quote}</Styled.Quote>
      {author && <Styled.Author>{author}</Styled.Author>}
    </Styled.HighlightedQuoteWrapper>
  );
};

export default HighlightedQuote;
