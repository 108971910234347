import { styled } from "@mui/material";
import { colors } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const SearchModalContainer = styled("div")(({ theme }) => ({
  zIndex: zIndex.modal,
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  padding: theme.spacing(20, 0, 30),
  backgroundColor: colors.neutral0,
  overflow: "auto",
  [theme.breakpoints.up("lg")]: {
    scrollbarGutter: "stable",
  },
}));
