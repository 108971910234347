import { ReactNode } from "react";
import { IconEnum } from "@constants/consts";
import * as Styled from "./Label.styled";

interface LabelProps {
  id?: string;
  icon?: IconEnum;
  iconTestId?: string;
  text: number | string;
  children?: ReactNode;
  className?: any;
}
const Label: React.FC<LabelProps> = ({ id, icon, iconTestId, text, className, children }) => {
  return (
    text && (
      <Styled.LabelWrapper id={id} className={className}>
        {icon && <Styled.LabelIcon icon={icon} data-testid={iconTestId} />}
        <span>{text}</span>
        {children}
      </Styled.LabelWrapper>
    )
  );
};

export default Label;
