import { Theme } from "@mui/material";
import * as StyledFooter from "@navigation/Footer/Footer.styled";
import * as StyledSubscription from "@navigation/Subscription/Subscription.styled";
import { largerScreenSizesPlayerHeight, smallerScreenSizesPlayerHeight } from "@audio/AudioPlayer.styled";
import { ButtonContainer } from "@common/Buttons";
import { colors } from "@constants/cssVariables";
import { StyleFunction } from "../OutletStyles";

const sharedRadiosStyles: StyleFunction = (theme: Theme) => ({
  [`body ${StyledFooter.FooterWrapper}`]: {
    backgroundColor: colors.neutral0,
    color: colors.neutral90,
    borderTop: `1px solid ${colors.neutral20}`,
    [`${StyledSubscription.Container}`]: {
      borderColor: colors.neutral20,

      [`${StyledSubscription.Input}`]: {
        backgroundColor: colors.neutral10,
      },
      [`${StyledSubscription.Title}`]: {
        color: colors.neutral90,
      },
      [`${StyledSubscription.Subtitle}`]: {
        color: colors.neutral60,
      },
    },
    [`${StyledFooter.SubmenuItemTitle}`]: {
      color: colors.neutral60,
    },
    [`${StyledFooter.SubmenuItemTitle}:hover`]: {
      color: colors.neutral90,
    },
    [`${StyledFooter.SubmenuTitle}`]: {
      color: colors.neutral90,
    },
    [`${ButtonContainer}`]: {
      backgroundColor: colors.button.secondaryBackgroundColor,
    },
    [`${ButtonContainer}:hover`]: {
      backgroundColor: colors.button.hoverSecondaryBackgroundColor,
    },
    [`${StyledFooter.RightsText}`]: {
      color: colors.neutral40,
      paddingBottom: smallerScreenSizesPlayerHeight,
      [theme.breakpoints.up("md")]: {
        paddingBottom: largerScreenSizesPlayerHeight,
      },
    },
    [`${StyledFooter.IconsTitle}`]: {
      color: colors.neutral90,
    },
  },
  [`body ${StyledSubscription.Container}`]: {
    [`${ButtonContainer}`]: {
      backgroundColor: colors.button.secondaryBackgroundColor,
    },
    [`${ButtonContainer}:hover`]: {
      backgroundColor: colors.button.hoverSecondaryBackgroundColor,
    },
    [`${StyledSubscription.LegalText} a`]: {
      color: colors.secondary,
    },
  },
});

export default sharedRadiosStyles;
