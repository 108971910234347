import { isAfter, isBefore } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { CampaignState } from "@constants/consts";

const getCampaignState = (startDate?: string, endDate?: string): CampaignState | undefined => {
  if (!startDate || !endDate) return;

  const now = utcToZonedTime(new Date(), "Europe/Amsterdam");
  const startDateUtc = utcToZonedTime(startDate, "UTC");
  const endDateUtc = utcToZonedTime(endDate, "UTC");

  if (isBefore(startDateUtc, now) && isAfter(endDateUtc, now)) {
    return CampaignState.ACTIVE;
  }

  if (isAfter(startDateUtc, now)) {
    return CampaignState.NOT_STARTED;
  }

  return CampaignState.EXPIRED;
};

export default getCampaignState;
