import AspectRatioContainer from "@common/AspectRatioContainer";
import * as Styled from "./ImageSkeleton.styled";

export const imageSkeletonTestId = "image-skeleton";

const ImageSkeleton: React.FC<{ aspectRatio: "16:9" | "1:1" | "2:3" }> = ({ aspectRatio }) => (
  <Styled.Skeleton data-testid={imageSkeletonTestId} variant="rounded">
    <AspectRatioContainer aspectRatio={aspectRatio} />
  </Styled.Skeleton>
);

export default ImageSkeleton;
