import getConfig from "next/config";
import * as Styled from "./ImageEmbed.styled";

export const imageEmbedImageTestId = "imageEmbedimage";

const {
  publicRuntimeConfig: { cloudinaryDomain },
} = getConfig();

const imageEmbedCustomCloudinaryLoader = ({ src, width }: { src: string; width: number }) => {
  const roundedBordersParamValue = width >= 900 ? "r_8" : "r_6";
  const params = ["f_auto", "c_limit", `w_${width}`, roundedBordersParamValue];

  return `${cloudinaryDomain}/image/fetch/${params.join(",")}/${src}`;
};

interface ImageEmbedProps {
  fileUrl: string;
  height: number;
  width: number;
  description?: string;
}

const ImageEmbed: React.FC<ImageEmbedProps> = ({ fileUrl, width, height, description }) => {
  return (
    <Styled.ImageEmbedContainer>
      <Styled.ImageEmbedImageContainer>
        <Styled.ImageEmbedImage
          loader={imageEmbedCustomCloudinaryLoader}
          src={fileUrl}
          alt={description || ""}
          height={height}
          width={width}
          sizes="(max-width: 900px) 100vw, 740px"
          data-testid={imageEmbedImageTestId}
        />
      </Styled.ImageEmbedImageContainer>
      <Styled.ImageEmbedDescription>{description}</Styled.ImageEmbedDescription>
    </Styled.ImageEmbedContainer>
  );
};

export default ImageEmbed;
