import { styled } from "@mui/material";
import * as StyledSubscription from "@navigation/Subscription/Subscription.styled";
import { ButtonContainer } from "@common/Buttons";
import { maxPageContentWidth, transitions } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";

export const FooterWrapper = styled("div")(({ theme }) => ({
  backgroundColor: colors.neutral90,
  color: colors.neutral0,
  width: "100%",
  marginTop: theme.spacing(20),
  padding: theme.spacing(10, 0, 12),
  transition: transitions.hamburgerMenu,

  div: {
    paddingTop: 0,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12, 0),
  },

  [`${StyledSubscription.Container}`]: {
    padding: theme.spacing(0, 0, 10),
    backgroundColor: "transparent",
    borderBottom: `1px solid ${colors.neutral70}`,
    marginBottom: theme.spacing(10),
    borderRadius: 0,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 10),
    },

    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 0, 10),
    },

    [`${StyledSubscription.TitleSubtitleContainer}`]: {
      [theme.breakpoints.up("md")]: {
        width: 650,
      },
      [theme.breakpoints.up("lg")]: {
        width: 850,
      },
    },

    [`${StyledSubscription.Title}`]: {
      color: colors.neutral0,
    },

    [`${StyledSubscription.Subtitle}`]: {
      color: colors.neutral30,
    },

    [`${StyledSubscription.InputAndButtonContainer}`]: {
      [theme.breakpoints.up("md")]: {
        width: 488,
      },

      [theme.breakpoints.up("lg")]: {
        width: 616,
      },
    },
  },
}));

export const ItemsWrapper = styled("div")(({ theme }) => ({
  maxWidth: maxPageContentWidth,
  margin: "0 auto",
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 20),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 24),
  },
}));

export const SubmenuContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(4),
  rowGap: theme.spacing(8),
  gridTemplateColumns: "1fr",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },

  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
}));

export const SubmenuItemWrapper = styled("div")<{ isLastItem: boolean; hasSubitems: boolean }>(
  ({ theme, isLastItem, hasSubitems }) => ({
    gridColumn: 1,

    ...(isLastItem && {
      display: "flex",
      flexDirection: "column",
      rowGap: theme.spacing(8),

      [theme.breakpoints.up("md")]: {
        gridColumn: "2",
        gridRow: "1 / span 3",
      },
    }),

    [theme.breakpoints.down("md")]: {
      ...(!hasSubitems && !isLastItem && { display: "none" }),
    },

    [theme.breakpoints.up("lg")]: {
      gridColumn: "auto",
      gridRow: "auto",
    },
  })
);

export const Submenu = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  gridColumn: 1,

  [`> ${ButtonContainer}`]: {
    width: 200,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      width: 177,
    },
  },
}));

export const SubmenuTitle = styled("div")(({ theme }) => ({
  font: fonts.header5,
  color: colors.neutral0,
  marginBottom: theme.spacing(0.5),
}));

export const SubmenuItemTitle = styled("div")(() => ({
  font: fonts.footer,
  color: colors.neutral30,
  ":hover": {
    color: colors.neutral0,
  },
}));

export const RightsText = styled("div")(({ theme }) => ({
  font: fonts.bodySmall,
  marginTop: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    width: 732,
  },
  [theme.breakpoints.up("lg")]: {
    width: 1040,
  },
  [theme.breakpoints.up("xl")]: {
    width: 1248,
  },
}));

export const IconsContainer = styled("div")<{ isDownloadAppIcon?: boolean }>(
  ({ theme, isDownloadAppIcon = false }) => ({
    display: "flex",
    flexDirection: "column",
    gap: isDownloadAppIcon ? theme.spacing(3) : theme.spacing(5),

    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(4),
    },
  })
);

export const IconsTitle = styled("p")(() => ({
  font: fonts.header5,
  color: colors.neutral0,
}));

export const IconItemsContainer = styled("div")<{ isDownloadAppIcon?: boolean }>(
  ({ theme, isDownloadAppIcon = false }) => ({
    display: "flex",
    gap: theme.spacing(4),

    [theme.breakpoints.up("md")]: {
      ...(!isDownloadAppIcon && { gap: theme.spacing(3) }),
    },
  })
);
