import { styled } from "@mui/material";
import { CrossIcon, LeftArrowIcon, RightArrowIcon } from "@common/Icons";
import { transitions } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const BackAndCloseContainer = styled("div")(({ theme }) => ({
  height: 56,
  width: "100%",
  position: "relative",

  [theme.breakpoints.up("md")]: {
    height: 64,
  },
}));

export const AnimationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: 320,
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    width: 400,
  },
}));

export const SidebarMenu = styled("div")<{ isHamburgerMenuOpen: boolean }>(({ isHamburgerMenuOpen, theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "fixed",
  top: 0,
  zIndex: zIndex.modal + 1,
  backgroundColor: colors.neutral90,
  width: 320,
  transition: transitions.hamburgerMenu,
  transform: isHamburgerMenuOpen ? "none" : "translateX(-320px)",

  [theme.breakpoints.up("md")]: {
    minWidth: 400,
    transform: isHamburgerMenuOpen ? "none" : "translateX(-400px)",
  },
}));

export const Options = styled("div")<{ level: number }>(({ level, theme }) => ({
  display: "inline-flex",
  minWidth: 320,
  flexDirection: "column",
  alignItems: "flex-start",
  height: `calc(100% - ${level === 1 ? "202px" : "56px"})`,
  marginTop: theme.spacing(5),
  overflowY: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",

  [theme.breakpoints.up("md")]: {
    height: `calc(100% - ${level === 1 ? "211px" : "64px"})`,
    marginTop: theme.spacing(4.5),
    minWidth: 400,
  },
}));

export const MenuItem = styled("div")(({ theme }) => ({
  width: 320,
  height: 50,
  position: "relative",
  display: "flex",
  justifycontent: "center",
  alignItems: "center",
  flexShrink: 0,

  "&:hover": {
    cursor: "pointer",
    backgroundColor: colors.primary,
  },

  [theme.breakpoints.up("md")]: {
    width: 400,
    height: 48,
  },
}));

export const MenuItemTitle = styled("p")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.bodyLarge,
  width: 272,
  height: 26,
  margin: theme.spacing(3, 6),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(2.5, 8),
    width: 336,
    height: 28,
  },
}));

export const BackContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  zIndex: zIndex.drawer,
  visibility: "hidden",
  display: "flex",
  position: "absolute",
  top: 16,
  left: 24,
  gap: theme.spacing(3),

  [theme.breakpoints.up("md")]: {
    top: 20,
    left: 32,
  },
}));

export const Back = styled(LeftArrowIcon)({
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 24,
    height: 24,
  },
});

export const BackText = styled("p")(({ theme }) => ({
  color: colors.neutral0,
  font: fonts.tab,
  padding: theme.spacing(0.5, 0),
}));

export const Close = styled(CrossIcon)(({ theme }) => ({
  zIndex: zIndex.drawer,
  cursor: "pointer",
  position: "absolute",
  right: 24,
  top: 8,
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 40,
    height: 40,
  },

  [theme.breakpoints.up("md")]: {
    top: 12,
  },
}));

export const RightArrow = styled(RightArrowIcon)(({ theme }) => ({
  cursor: "pointer",
  position: "absolute",
  right: 24,
  top: 12,
  svg: {
    display: "flex",
    color: colors.neutral0,
    width: 24,
    height: 24,
  },

  [theme.breakpoints.up("md")]: {
    right: 32,
  },
}));

export const SocialMediaContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  display: "flex",
  height: 146,
  width: 320,
  bottom: 0,
  left: 0,
  backgroundColor: colors.neutral90,
  padding: theme.spacing(4, 6),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4, 8),
    width: 400,
  },
}));

export const OptionsWrapper = styled("div")(() => ({
  position: "relative",
}));
