import React, { ChangeEvent, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Tealium from "@4tn/webx-analytics";
import { debounce } from "@mui/material";
import { ROUTE_PATHS, audioDashboardHeightMd, audioIconLabels } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import trackPlaylistClick from "@utils/audio/trackHistoryClick";
import featureTooling, { FeatureSlugs, useFeature } from "@utils/common/featureTooling";
import audioPlayerManager from "../../AudioPlayerManager/AudioPlayerManager";
import * as Styled from "./IconSection.styled";

const debouncedVolumeChange = debounce(audioPlayerManager.volumeChange, 100);

export const volumeIconDataTestId = "volumeIconDataTestId";
export const volumeMutedIconDataTestId = "volumeMutedIconDataTestId";
export const volumeBarDataTestId = "volumeBarDataTestId";
export const whatsAppIconAriaLabel = "Contact opnemen met de studio";
export const popoutIconAriaLabel = "Open in venster";

const handlePopOut = (event: React.MouseEvent, stationSlug: string) => {
  event.preventDefault();
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
    event_name: TEALIUM_EVENT_NAME.POPUP_CLICK,
    event_label: stationSlug,
  });

  // Remove 1px from the height to avoid visible white space in the bottom of the popout
  const features = `width=900,height=${audioDashboardHeightMd - 1},top=100,left=100,toolbar=0,location=0,menubar=0,status=0,scrollbars=1,resizable=1`;
  const popoutWindow = window.open(`${ROUTE_PATHS.POPOUT}${stationSlug}`, "_blank", features);
  window.eventBus.dispatch("popoutWindow", { window: popoutWindow });
};

const IconWrapper: React.FC<{
  label: string;
  inAudioDashboard?: boolean;
  children: React.ReactNode;
}> = ({ label, children, inAudioDashboard }) => {
  return inAudioDashboard ? (
    <Styled.IconWrapper>
      {children}
      <Styled.IconLabel>{label}</Styled.IconLabel>
    </Styled.IconWrapper>
  ) : (
    children
  );
};

const IconSection = ({
  station,
  inAudioDashboard,
  isVideoPlaying,
}: {
  station: AudioStationEntry;
  inAudioDashboard?: boolean;
  isVideoPlaying: boolean;
}) => {
  const [volume, setVolume] = useState(audioPlayerManager.isMuted ? 0 : audioPlayerManager.volume);
  const { variables: featureVariables } = useFeature<{ whatsappUrl: string }>(FeatureSlugs.CONTACT_STUDIO);
  const router = useRouter();

  useEffect(() => {
    const handlePlayerVolume = (event: AudioPlayerEventParams["playerVolume"]) => {
      setVolume(event.volume);
    };
    const handleMuteToggle = (event: AudioPlayerEventParams["mute"]) => {
      setVolume(event.muted ? 0 : audioPlayerManager.volume);
    };
    audioPlayerManager.on("playerVolume", handlePlayerVolume);
    audioPlayerManager.on("mute", handleMuteToggle);
    return () => {
      audioPlayerManager.off("playerVolume", handlePlayerVolume);
      audioPlayerManager.off("mute", handleMuteToggle);
    };
  }, []);

  const handleVolumeMute = () => {
    if (volume) {
      audioPlayerManager.mute();
      return;
    }
  };

  const handleVolumeUnmute = () => {
    audioPlayerManager.volumeChange(audioPlayerManager.volume);
  };

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newVolume = Number(event.target.value);

    setVolume(newVolume);
    debouncedVolumeChange(newVolume);
  };

  const handleOpenWhatsapp = () => {
    Tealium.link({
      event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
      event_name: TEALIUM_EVENT_NAME.PLAYER_CLICK,
      event_label: TEALIUM_EVENT_LABEL.CONTACT_STUDIO,
    });
  };

  return (
    <Styled.IconSectionContainer inAudioDashboard={inAudioDashboard}>
      {station?.videoGuid && (
        <IconWrapper label={audioIconLabels.VIDEO_ICON} inAudioDashboard={inAudioDashboard}>
          {!isVideoPlaying && (
            <Styled.StyledVideoIcon onClick={() => audioPlayerManager.switchVisualStream(true, station)} />
          )}
          <Styled.StyledVideoIconFilled
            onClick={() => audioPlayerManager.switchVisualStream(false, station)}
            isVideoPlaying={isVideoPlaying}
          />
        </IconWrapper>
      )}
      {featureVariables?.whatsappUrl && (
        <IconWrapper label={audioIconLabels.WHATSAPP_ICON} inAudioDashboard={inAudioDashboard}>
          <Link
            href={featureVariables.whatsappUrl}
            onClick={handleOpenWhatsapp}
            target="_blank"
            aria-label={whatsAppIconAriaLabel}
          >
            <Styled.StyledWhatsAppIcon />
          </Link>
        </IconWrapper>
      )}
      <IconWrapper label={audioIconLabels.VOLUME_ICON} inAudioDashboard={inAudioDashboard}>
        <Styled.VolumeControlsContainer volumePercentage={`${(volume * 100).toString()}%`}>
          {!!volume && <Styled.VolumeIcon onClick={handleVolumeMute} data-testid={volumeIconDataTestId} />}
          <Styled.VolumeMutedIcon
            onClick={handleVolumeUnmute}
            isVolumeMuted={!volume}
            data-testid={volumeMutedIconDataTestId}
          />
          {featureTooling.isDesktop && (
            <Styled.VolumeBarContainer>
              <Styled.VolumeBar
                type="range"
                min={0}
                max={1}
                step={0.01}
                value={volume}
                onChange={handleVolumeChange}
                data-testid={volumeBarDataTestId}
              />
            </Styled.VolumeBarContainer>
          )}
        </Styled.VolumeControlsContainer>
      </IconWrapper>
      {inAudioDashboard && station.slug && (
        <IconWrapper label={audioIconLabels.PLAYLIST} inAudioDashboard={inAudioDashboard}>
          <Link href={`${ROUTE_PATHS.PLAYLIST}${station.slug}`} onClick={() => trackPlaylistClick(station.slug)}>
            <Styled.StyledPlaylistIcon />
          </Link>
        </IconWrapper>
      )}
      {featureTooling.isDesktop && !router.pathname.startsWith(ROUTE_PATHS.POPOUT) && (
        <IconWrapper label={audioIconLabels.POP_OUT_ICON} inAudioDashboard={inAudioDashboard}>
          <Styled.StyledPopOutIcon
            aria-label={popoutIconAriaLabel}
            onClick={(event) => handlePopOut(event, station.slug)}
          />
        </IconWrapper>
      )}
    </Styled.IconSectionContainer>
  );
};

export default IconSection;
