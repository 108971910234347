import ImageSkeleton from "../ImageSkeleton/ImageSkeleton";
import TextSkeleton from "../TextSkeleton/TextSkeleton";
import * as Styled from "./ArticleSkeleton.styled";

const ArticleSkeleton: React.FC<{ showCategory?: boolean }> = ({ showCategory }) => (
  <Styled.ArticleSkeletonWrapper>
    <ImageSkeleton aspectRatio="16:9" />
    <Styled.TextContainer>
      <TextSkeleton width={"100%"} />
      <TextSkeleton width={"100%"} />
      <TextSkeleton width={"70%"} />
      {showCategory && <TextSkeleton sx={{ marginTop: 2, display: ["none", "none", "block"] }} width={"50%"} />}
    </Styled.TextContainer>
  </Styled.ArticleSkeletonWrapper>
);

export default ArticleSkeleton;
