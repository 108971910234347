import * as Styled from "./LinkList.styled";
import LinkListItem from "./LinkListItem";

interface LinkListProps {
  title: string;
  items: PageLink[];
}

const LinkList: React.FC<LinkListProps> = ({ title, items }) => {
  return (
    <Styled.LinkListContainer>
      <Styled.LinkListTitle>{title}</Styled.LinkListTitle>
      <Styled.LinkListItemsContainer>
        {items.map((item, index) => (
          <LinkListItem collectionTitle={title} key={item.id} position={index + 1} {...item} />
        ))}
      </Styled.LinkListItemsContainer>
    </Styled.LinkListContainer>
  );
};

export default LinkList;
