import { AudioPlayerType } from "@constants/consts";
import { Show, Track } from "@utils/audio/dataModels";
import { noticeError } from "@utils/common/newRelic";

class WebAudioPlayer {
  protected audioElement: HTMLAudioElement;
  isPlaying: boolean = false;
  type = AudioPlayerType.WEB;
  nowPlayingTrack: Track | null = null;
  nowPlayingShow: Show | null = null;
  dispatch: <K extends keyof AudioPlayerEventParams>(key: K, params: AudioPlayerEventParams[K]) => void;
  getVolume: () => number;

  constructor(dispatch: WebAudioPlayer["dispatch"], getVolume: WebAudioPlayer["getVolume"]) {
    this.dispatch = dispatch;
    this.getVolume = getVolume;

    if (typeof Audio != "undefined") {
      // Browser-only code
      this.audioElement = new Audio();
      this.audioElement.preload = "metadata";

      this.audioElement.addEventListener("loadedMetadata", () => {
        if (!this.isPlaying) return;
        if (this.audioElement.duration === Infinity || isNaN(this.audioElement.duration)) {
          console.log("This is likely a livestream.");
          this.dispatch("play", { details: "Playing mp3 livestream" });
        } else {
          console.log("This is likely an on-demand MP3 file.");
          this.dispatch("play", { details: "Playing on-demand MP3 file" });
        }
      });

      this.audioElement.addEventListener("play", () => {
        console.log("WebAudioPlayer > play", this.audioElement.duration);
        this.dispatch("play", { details: "Started playing mp3" });
        this.isPlaying = true;
      });
      this.audioElement.addEventListener("pause", () => {
        console.log("WebAudioPlayer > pause");
        if (this.audioElement.currentTime !== this.audioElement.duration) {
          this.dispatch("pause", { details: "Paused" });
        }
        this.isPlaying = false;
      });
      this.audioElement.addEventListener("ended", () => {
        console.log("WebAudioPlayer > ended");
        this.dispatch("complete", { details: "Ended" });
        this.isPlaying = false;
      });
      this.audioElement.addEventListener("volumeChange", (event) => {
        console.log("WebAudioPlayer > volumeChange", event);
        this.dispatch("playerVolume", { volume: this.audioElement.volume });
      });
      this.audioElement.addEventListener("playerError", (event) => {
        const { error } = event as ErrorEvent;
        noticeError(error);
      });
    }
  }

  switchVisualRadio(_shouldToggleVideo: boolean) {}

  load(_media: AudioStationEntry): void {}

  play(source: string): void {
    this.type = AudioPlayerType.WEB;
    this.audioElement.src = source;
    const volume = this.getVolume();

    if (volume === 0) {
      this.audioElement.muted = true;
    } else if (volume) {
      this.audioElement.volume = volume;
    }
    this.audioElement.play();
  }

  addEventListener(event: string, callback: EventListener): void {
    this.audioElement.addEventListener(event, callback);
  }

  resume(): void {
    this.audioElement.play();
  }

  pause(): void {
    this.audioElement.pause();
  }

  async stop(): Promise<void> {
    this.audioElement.pause();
    this.audioElement.src = ""; // Reset source to stop the audio completely
  }

  complete(): void {
    this.audioElement.pause();
    this.audioElement.src = ""; // Reset source to stop the audio completely
  }

  mute(): void {
    this.audioElement.muted = true;
    this.dispatch("mute", { muted: true });
  }

  unmute(): void {
    this.audioElement.muted = false;
    this.dispatch("mute", { muted: false });
  }

  volumeChange(volume: number): void {
    if (volume) {
      if (this.audioElement.muted) this.audioElement.muted = false;
      this.audioElement.volume = volume;
      this.dispatch("playerVolume", { volume });
    }
  }
}

export default WebAudioPlayer;
