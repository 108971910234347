import Image from "next/image";
import Link from "next/link";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { TEALIUM_EVENT_LABEL } from "@constants/tealium.constants";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./GalleryHeader.styled";

export const playButtonTestId = "gallery-header-play-button";

const GalleryHeader: React.FC<{ items: Array<PageLink> }> = ({ items }) => {
  return (
    <Styled.GalleryContainer>
      {items.map((item, index) => (
        <Styled.Tile key={item.id}>
          <Link
            href={item.url}
            target={item.target}
            onClick={() =>
              trackContentClick({
                position: index + 1,
                label: TEALIUM_EVENT_LABEL.HEADER_PROMO,
                ...item,
              })
            }
          >
            <Styled.AspectRatio aspectRatio="16:9">
              <Image
                src={item.imageUrl}
                alt={item.title}
                fill
                priority
                sizes={`(max-width: 900px) ${index === 0 ? 100 : 50}vw, ${index === 0 ? 66 : 33}vw`}
              />
              {item.hasVideo && (
                <Styled.PlayIcon
                  icon={IconEnum.PLAY_ICON_FILLED}
                  size={BUTTON_SIZE.MEDIUM}
                  variant={VARIANT.PRIMARY}
                  color={colors.neutral0}
                  isNonInteractive
                  data-testid={playButtonTestId}
                />
              )}
            </Styled.AspectRatio>
            <Styled.Title hasVideo={item.hasVideo}>{item.title}</Styled.Title>
          </Link>
        </Styled.Tile>
      ))}
    </Styled.GalleryContainer>
  );
};

export default GalleryHeader;
