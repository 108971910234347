import { Skeleton, styled } from "@mui/material";

export const SwimlaneItemSkeletonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "0 0 40%",
  margin: theme.spacing(1),

  [theme.breakpoints.up("md")]: {
    flex: "0 0 35%",
  },

  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

export const SwimlaneItemImageSkeleton = styled(Skeleton)(({ theme }) => ({
  maxWidth: "none",
  padding: theme.spacing(0.5),

  [theme.breakpoints.up("lg")]: {
    width: 102,
  },
}));

export const SwimlaneItemTextSkeletonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: theme.spacing(2),
  padding: theme.spacing(3, 0, 1),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4, 0, 1),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(2, 0, 2, 3),
  },
}));
