import { GlobalStyles, Theme } from "@mui/material";
import * as StyledFooter from "@navigation/Footer/Footer.styled";
import * as StyledSubscription from "@navigation/Subscription/Subscription.styled";
import * as StyledAudioDashboard from "@audio/AudioDashboard/AudioDashboard.styled";
import * as StyledAudioPlayer from "@audio/AudioPlayer.styled";
import * as StyledAudioPlayerIconSection from "@audio/components/IconSection/IconSection.styled";
import * as StyledMainPlayerControls from "@audio/components/MainPlayerControls/MainPlayerControls.styled";
import * as StyledNowPlaying from "@audio/components/NowPlaying/NowPlaying.styled";
import { ButtonContainer } from "@common/Buttons";
import * as StyledSquareCollection from "@pageContent/SquareCollection/SquareCollection.styled";
import { TextLabel } from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem/InfiniteCollectionItem.styled";
import { colors, fonts } from "@constants/cssVariables";
import { StyleFunction } from "../OutletStyles";
import sharedRadiosStyles from "./radio.styles";

const styles: StyleFunction = (theme: Theme) => ({
  ...sharedRadiosStyles(theme),
  body: {
    [`${StyledSubscription.Container}`]: {
      [`${StyledSubscription.Input}`]: {
        borderRadius: "4px 0 0 4px",
      },
      [`${ButtonContainer}`]: {
        backgroundColor: colors.secondary,
        borderRadius: "0 4px 4px 0",
      },
      [`${ButtonContainer}:hover`]: {
        backgroundColor: colors.button.hoverPrimaryBackgroundColor,
      },
    },
    [`${StyledFooter.FooterWrapper} ${ButtonContainer}`]: {
      backgroundColor: colors.secondary,
    },
    [`${StyledFooter.FooterWrapper} ${ButtonContainer}:hover`]: {
      backgroundColor: colors.button.hoverPrimaryBackgroundColor,
    },
    [`${StyledAudioPlayerIconSection}`]: {
      [theme.breakpoints.up("md")]: {
        top: 12,
      },
    },
    [`${StyledAudioPlayerIconSection.IconWrapper}`]: {
      [`&:has(${StyledAudioPlayerIconSection.StyledVideoIcon})`]: {
        display: "none",
      },
    },
    [`${StyledAudioPlayerIconSection.VolumeControlsContainer}`]: {
      [theme.breakpoints.only("sm")]: {
        position: "relative",
      },
    },
    [`${TextLabel}`]: {
      [theme.breakpoints.up("sm")]: {
        textTransform: "uppercase",
      },
    },
    [`${StyledSquareCollection.StyledAspectRationContainer}`]: {
      '&[data-is-mini="true"]': {
        borderRadius: 4,
      },
    },
    [`${StyledSquareCollection.CardTitleWrapper}`]: {
      [`${StyledSquareCollection.TitleMini}`]: {
        borderRadius: 2,

        [theme.breakpoints.up("sm")]: {
          borderRadius: 4,
        },

        "& p": {
          textTransform: "unset",
        },
      },
    },
    [`${StyledAudioPlayer.AudioPlayerContainer} ${ButtonContainer}`]: {
      backgroundColor: colors.button.primaryBackgroundColor,
    },
    [`${StyledAudioPlayer.AudioPlayerContainer} ${StyledMainPlayerControls.MainPlayerControlsContainer} ${ButtonContainer}:hover`]:
      {
        backgroundColor: colors.button.hoverPrimaryBackgroundColor,
      },
    [`${StyledAudioPlayer.AudioPlayerContainer} ${ButtonContainer} svg, ${StyledNowPlaying.NowPlayingContainer} ${ButtonContainer} p, ${StyledNowPlaying.NowPlayingContainer} ${ButtonContainer} p:hover, ${StyledNowPlaying.NowPlayingContainer} ${ButtonContainer} p:active`]:
      {
        color: colors.button.primaryTextColor,
      },
  },
  [`${StyledAudioDashboard.TrackContent} ${StyledAudioPlayerIconSection.IconSectionContainer} ${StyledAudioPlayerIconSection.IconWrapper}`]:
    {
      display: "none !important",
    },
  [`${TextLabel}`]: {
    ["&[id='doe mee!'], &[id='verlopen']"]: {
      [theme.breakpoints.up("sm")]: {
        borderRadius: 4,
        span: {
          height: 12,
        },
      },
    },
  },
  [`${StyledAudioPlayer.AudioPlayerContainer}  ${StyledAudioPlayerIconSection.IconSectionContainer}`]: {
    [theme.breakpoints.between("xs", "md")]: {
      top: 0,
    },
  },
  [`${StyledAudioDashboard.AudioDashboardContainer}`]: {
    [theme.breakpoints.between("xs", "md")]: {
      marginBottom: theme.spacing(8),
    },
    [`${StyledAudioDashboard.AudioDashboardContent}`]: {
      [`${StyledAudioDashboard.LiveLabel}`]: {
        font: fonts.header7,
        color: colors.primary140,
        borderRadius: 4,
        display: "flex",
        [theme.breakpoints.up("md")]: {
          padding: theme.spacing(1, 2.5),
        },
      },
      [`${StyledAudioDashboard.VideoAndAudioContainer}`]: {
        [theme.breakpoints.only("xs")]: {
          gap: theme.spacing(2.5),
          width: "100%",
        },
      },

      [`${StyledAudioDashboard.StationContainer}`]: {
        [`${StyledMainPlayerControls.MainPlayerControlsContainer} ${StyledMainPlayerControls.StyledIconButton}`]: {
          backgroundColor: `${colors.button.primaryBackgroundColor}`,
          "&:hover": {
            backgroundColor: `${colors.button.hoverPrimaryBackgroundColor}`,
          },
          "div > svg": {
            color: `${colors.button.primaryTextColor}`,
          },
        },

        [`${StyledAudioDashboard.StationInfo}`]: {
          [`${StyledAudioDashboard.StationInfoTitle}`]: {
            font: fonts.header1,
            color: colors.neutral0,
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            [theme.breakpoints.up("md")]: {
              font: fonts.header2,
            },
          },

          [`${StyledAudioDashboard.StationInfoDescription}`]: {
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        },
      },

      [`${StyledAudioDashboard.TrackContent} ${StyledAudioDashboard.TrackContainer}`]: {
        [`${StyledAudioDashboard.CurrentlyPlayingTrack}`]: {
          borderRadius: 4,

          [`${StyledAudioDashboard.NowPlayingText}`]: {
            font: fonts.bodySmall,
          },
        },

        [`${StyledAudioDashboard.TrackHistoryButton}`]: {
          borderRadius: 4,
        },
      },
    },

    [`${StyledAudioDashboard.AudioDashboardBackground}`]: {
      [`${StyledAudioDashboard.ShowImageContainer} ${StyledAudioDashboard.ImageBackground}`]: {
        backgroundImage: "url('/icons/skyradio/circle.png')",
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: "100%",
        height: "100%",
        scale: 0.95,
        [theme.breakpoints.between("xs", "md")]: {
          scale: 0.85,
        },
      },
    },
  },
});

const SkyRadioStyles = () => <GlobalStyles styles={styles} />;

export default SkyRadioStyles;
