import { AD_IDS, ROUTE_PATHS, isClient } from "@constants/consts";
import featureTooling, { FeatureSlugs } from "@utils/common/featureTooling";
import getHostConfig from "@utils/common/getHostConfig";

type PageTypes = "home" | "video" | "article";

const pageSlotConfig: Record<string, string[]> = {
  home: [AD_IDS.topBanner],
  article: [AD_IDS.topBanner, AD_IDS.contentBanner, AD_IDS.bottomBanner, AD_IDS.articlePanelBanner],
  video: [AD_IDS.contentBanner, AD_IDS.bottomBanner],
};

export async function fetchDpgAds(url: string): Promise<{ html: string | null; requestBody?: DpgAdsRequest } | null> {
  if (!featureTooling.isEnabled(FeatureSlugs.DISPLAY_ADS)) return null;
  const [path] = url.split("?");
  const navigation = path.split("/").filter(Boolean);

  const pageType = getPageType(path);
  const componentId = navigation.pop() || "";

  if (isClient && window.dpgPreviousRequest?.componentId === componentId) {
    // skip request when same request was sent last
    window.eventBus.dispatch("triggerAd", {});
    return null;
  }

  const { displayAdsEndpoint, dpgApiKey } = getHostConfig();

  const previousNavigation = isClient ? window.dpgPreviousRequest : undefined;

  const slots = pageType ? pageSlotConfig[pageType] : [];

  if (pageType === "home") navigation.push(pageType);

  const body = {
    slots,
    pageType,
    navigation,
    componentId,
    previousPageInfo: previousNavigation,
  };

  try {
    const response = await fetch(displayAdsEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": dpgApiKey,
      },
      body: JSON.stringify(body),
    });

    if (isClient) window.dpgPreviousRequest = body;

    const adHtml = await response.json();

    return { html: adHtml.html, requestBody: body };
  } catch (err) {
    console.log(err);
    return { html: null };
  }
}

export function injectSnippet(html: string | null) {
  if (!html) return;
  document.head.appendChild(document.createRange().createContextualFragment(html));
}

function getPageType(path: string): PageTypes | undefined {
  const isHomePage = path.split("/").filter(Boolean).length === 0;
  const pageTypeMappings: Record<string, PageTypes> = {
    [ROUTE_PATHS.VIDEO.VIDEOS]: "video",
    [ROUTE_PATHS.ARTICLE]: "article",
  };

  if (isHomePage) return "home";

  for (const [key, type] of Object.entries(pageTypeMappings)) {
    if (path === key || path.includes(key)) {
      return type;
    }
  }

  return undefined;
}
