import AspectRatioContainer from "@common/AspectRatioContainer";
import TextSkeleton from "../TextSkeleton";
import * as Styled from "./SwimlaneItemSkeleton.styled";

export const swimlaneItemImageSkeletonTestId = "swimlaneItemImageSkeletonTestId";

const SwimlaneItemSkeleton: React.FC = () => (
  <Styled.SwimlaneItemSkeletonContainer>
    <Styled.SwimlaneItemImageSkeleton variant="rounded" data-testid={swimlaneItemImageSkeletonTestId}>
      <AspectRatioContainer aspectRatio="16:9" />
    </Styled.SwimlaneItemImageSkeleton>
    <Styled.SwimlaneItemTextSkeletonContainer>
      <TextSkeleton width={"100%"} />
      <TextSkeleton width={"100%"} />
      <TextSkeleton width={"55%"} />
    </Styled.SwimlaneItemTextSkeletonContainer>
  </Styled.SwimlaneItemSkeletonContainer>
);

export default SwimlaneItemSkeleton;
