import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

export const LinkListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

export const LinkListTitle = styled("p")(() => ({
  font: fonts.header3,
  color: colors.neutral90,
}));

export const LinkListItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));
