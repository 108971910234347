import getConfig from "next/config";
import { subDays } from "date-fns";
import { print, stripIgnoredCharacters } from "graphql";
import gql from "graphql-tag";
import type { DocumentNode } from "graphql/language/ast";
import { AudioType, radioProfile, tableNames } from "@constants/consts";
import DynamoDBUtils from "@utils/common/dynamo";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import { Playout, Schedule, Show, Station, Track, TrackHistoryItem } from "./dataModels";

const {
  publicRuntimeConfig: { audioApiEndpoint, audioXApiKey, appsyncApiKey, appsyncHost },
} = getConfig();

const RadioScheduleQuery = gql`
  query RadioScheduleQuery($stationSlug: String, $startDate: AWSDateTime, $endDate: AWSDateTime) {
    station(slug: $stationSlug) {
      schedules(broadcastStartDate: $startDate, broadcastEndDate: $endDate) {
        broadcastStartDate
        broadcastEndDate
        id
        show {
          title
          slug
          images {
            uri
            type
            imageType
            variant {
              slug
            }
          }
        }
      }
    }
  }
`;

const GetPlayouts = gql`
  query GetPlayouts($slug: String!, $nextToken: String, $startDate: AWSDateTime) {
    station(slug: $slug) {
      getPlayouts(nextToken: $nextToken, limit: 100, broadcastStartDate: $startDate) {
        playouts {
          id
          broadcastDate
          track {
            isrc
            id
            title
            artistName
            media {
              id
              source
              type
              title
              uri
            }
            images {
              title
              uri
            }
          }
        }
        nextToken
      }
    }
  }
`;

const getStation = gql`
  query GetStation($slug: String!) {
    station(slug: $slug) {
      id
      type
      title
      shortTitle
      slug
      media {
        type
        uri
        source
      }
      images {
        uri
        type
        description
        imageType
      }
      references {
        ... on ExternalEmbed {
          embedId
        }
      }
    }
  }
`;

const onStationEvent = `subscription onStationEvent($profile: String!, $slug: String!) {
    stationEvent(slug: $slug) {
      slug
      id
      title
      playouts(profile: $profile) {
        type
        broadcastDate
        track {
          id
          isrc
          title
          artistName
          images {
            uri
            imageType
            title
          }
        }
      }
    }
}`;

const onScheduleEvent = `
  subscription onStationEvent($profile: String!, $slug: String!) {
    scheduleEvent(slug: $slug, profile: $profile) {
      slug
      schedule {
        broadcastStartDate
        broadcastEndDate
        show {
          id
          slug
          title
          images {
            uri
            type
            imageType
            variant {
              slug
            }
          }
        }
      }
    }
  }
`;

const CurrentTrackQuery = gql`
  query CurrentTrackQuery($stationSlug: String!, $profile: String!) {
    station(slug: $stationSlug) {
      playouts(profile: $profile) {
        track {
          id
          title
          artistName
          images {
            uri
            imageType
            title
          }
        }
      }
    }
  }
`;

const CurrentShowQuery = gql`
  query CurrentShowQuery($stationSlug: String!) {
    station(slug: $stationSlug) {
      slug
      schedules {
        broadcastStartDate
        broadcastEndDate
        show {
          id
          slug
          title
          images {
            uri
            imageType
            title
          }
        }
      }
    }
  }
`;

export async function fetchCurrentTrack(stationSlug: string): Promise<Track | null> {
  const { data } = await fetchAudioData(CurrentTrackQuery, {
    stationSlug,
    profile: radioProfile,
  });

  if (data?.station && Array.isArray(data.station.playouts) && data.station.playouts.length > 0) {
    const trackData = data.station.playouts[0].track;
    if (trackData) {
      return new Track(trackData);
    }
  }

  return null;
}

const getShow = async (
  showData: UniversalApiShow,
  options: { broadcastStartDate: string; broadcastEndDate: string }
): Promise<AudioShowEntry> => {
  const show =
    typeof window !== "undefined"
      ? await fetchByEndpoint<AudioShowEntry>(`/api/audio/${showData.slug}`)
      : await DynamoDBUtils.getItemBySlug<Show>(tableNames.audio, showData.slug);

  return show || new Show(showData, options);
};

export async function fetchCurrentShow(stationSlug: string): Promise<Show | null> {
  try {
    const { data } = await fetchAudioData(CurrentShowQuery, {
      stationSlug,
    });
    if (data?.station?.schedules?.length) {
      const { show, broadcastStartDate, broadcastEndDate } = data.station.schedules[0];
      return getShow(show, { broadcastStartDate, broadcastEndDate });
    }
  } catch (error) {
    console.error(error);
  }

  return null;
}

export default async function fetchAudioData(
  query: DocumentNode,
  variables: {
    stationSlug?: string;
    slug?: string;
    startDate?: Date;
    endDate?: Date;
    nextToken?: string | null;
    skip?: number;
    profile?: string;
  }
): Promise<{ data: { station: UniversalApiStation } | null }> {
  const queryParams = new URLSearchParams({
    query: stripIgnoredCharacters(print(query)),
    variables: JSON.stringify(variables),
  });

  const response = await fetch(`${audioApiEndpoint}?${queryParams.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": audioXApiKey,
      Profile: radioProfile,
    },
  });
  const { data, errors } = await response.json();

  if (errors?.length) {
    throw new Error(errors[0].message);
  }

  return { data };
}

export async function fetchRadioSchedule(startDate: Date, endDate: Date, stationSlug?: string): Promise<Schedule[]> {
  if (!stationSlug) return [];

  return fetchAudioData(RadioScheduleQuery, {
    stationSlug,
    startDate,
    endDate,
  }).then(({ data }) => {
    if (!data?.station.schedules) return [];
    return data.station.schedules.map((schedule: UniversalApiRadioSchedule) => new Schedule(schedule));
  });
}

export async function fetchStationMetadata(slug: string): Promise<Station | null> {
  return fetchAudioData(getStation, { slug }).then(({ data }) => {
    if (!data?.station) return null;
    return new Station(data.station);
  });
}

export async function fetchPlayouts(
  slug: string,
  nextToken: string | null,
  skip: number
): Promise<{ items: Playout[]; nextToken: string }> {
  const startDate = subDays(new Date(), 1);

  return fetchAudioData(GetPlayouts, { slug, nextToken, startDate, skip }).then(({ data }) => {
    if (!data?.station.getPlayouts) return { items: [], nextToken: "" };
    return {
      items: data?.station.getPlayouts.playouts.map((playout: UniversalApiPlayout) => new Playout(playout)),
      nextToken: data.station.getPlayouts.nextToken,
    };
  });
}

export const getTrackHistory = async ({
  slug,
  itemLimitPerPage,
  maxItems = Infinity,
}: {
  slug?: string;
  itemLimitPerPage: number;
  maxItems?: number;
}): Promise<TrackHistoryItem[][]> => {
  if (!slug) return [];
  const endDate = new Date();
  const startDate = subDays(endDate, 1);

  const [showResult, playoutResult] = await Promise.all([
    fetchRadioSchedule(startDate, endDate, slug),
    fetchPlayouts(slug, null, 100),
  ]);

  const playouts = playoutResult.items;
  let token = playoutResult.nextToken;

  while (token && playouts.length < maxItems) {
    const { items: playoutItems, nextToken } = await fetchPlayouts(slug, token, 100);
    token = nextToken;
    playouts.push(...playoutItems);
  }

  const items = [...showResult, ...playouts].sort((a, b) => b.broadcastDate.getTime() - a.broadcastDate.getTime());

  const pages: TrackHistoryItem[][] = [];
  while (items.length) {
    pages.push(items.splice(0, itemLimitPerPage));
  }
  return pages;
};

function getWebsocketUrl() {
  const header = Buffer.from(
    JSON.stringify({
      host: appsyncHost,
      "x-api-key": appsyncApiKey,
    })
  ).toString("base64");
  const payload = btoa(JSON.stringify({}));
  const url = `wss://${appsyncHost}/graphql/realtime?header=${header}&payload=${payload}&protocol=graphql-ws`;

  return url;
}

export class StationSubscription {
  websocket: WebSocket;
  onEvent: (data: Track | Show) => void;
  type: string;
  query: string;
  constructor(slug: string, type: AudioType.TRACK | AudioType.SHOW, onEvent: (data: Track | Show) => void) {
    const url = getWebsocketUrl();
    this.query = type === "track" ? onStationEvent : onScheduleEvent;
    this.type = type;
    this.websocket = new WebSocket(url, ["graphql-ws"]);
    this.websocket.onopen = () => {
      this.websocket.send(
        JSON.stringify({
          type: "connection_init",
        })
      );
    };

    this.websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case "connection_ack":
          this.startSubscription(slug);
          break;
        case "error":
          console.error(message);
          break;
        case "data":
          if (this.type === AudioType.TRACK) {
            const trackData = message.payload.data.stationEvent.playouts[0]?.track;

            if (trackData) {
              const track = new Track(trackData);
              onEvent(track);
            }
          }
          if (this.type === AudioType.SHOW) {
            const { show, broadcastStartDate, broadcastEndDate } = message.payload.data.scheduleEvent.schedule;

            if (show) {
              getShow(show, { broadcastStartDate, broadcastEndDate }).then((show) => {
                onEvent(show);
              });
            }
          }
          break;
      }
    };
  }

  private startSubscription(slug: string) {
    const subscribeMessage = {
      id: window.crypto.randomUUID(),
      type: "start",
      payload: {
        data: JSON.stringify({
          query: this.query,
          variables: { profile: radioProfile, slug },
        }),
        extensions: {
          authorization: {
            "x-api-key": appsyncApiKey,
            host: appsyncHost,
          },
        },
      },
    };
    this.websocket.send(JSON.stringify(subscribeMessage));
  }

  cleanUp() {
    this.websocket.close();
  }
}
