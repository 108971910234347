import { Skeleton as MuiSkeleton, styled } from "@mui/material";
import { colors } from "@constants/cssVariables";

export const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
  backgroundColor: colors.neutral10,
  borderRadius: 26,
  width: "100%",
  maxWidth: "none",
  maxHeight: 52,

  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));
