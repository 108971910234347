import Tealium from "@4tn/webx-analytics";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";

const trackPlaylistClick = (stationSlug: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.PLAYER,
    event_name: TEALIUM_EVENT_NAME.PLAYER_CLICK,
    event_label: `${TEALIUM_EVENT_LABEL.PLAYLIST} | ${stationSlug}`,
  });
};

export default trackPlaylistClick;
