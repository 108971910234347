import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import * as StyledInfiniteCollection from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";
import * as InfiniteCollectionItemStyled from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem/InfiniteCollectionItem.styled";
import * as StyledArticleSkeleton from "@pageContent/common/Skeletons/ArticleSkeleton/ArticleSkeleton.styled";
import { colors, fonts } from "@constants/cssVariables";

export const CategoryBasedCollectionWrapper = styled("div")<{ enlargeFirstItem?: boolean }>(
  ({ theme, enlargeFirstItem }) => ({
    ...(enlargeFirstItem && {
      [theme.breakpoints.down("sm")]: {
        [`${StyledInfiniteCollection.ItemsContainer} > ${InfiniteCollectionItemStyled.ItemContainer}:nth-of-type(1)`]: {
          display: "unset",
          position: "relative",
          [`${InfiniteCollectionItemStyled.ImageContainer}`]: {
            display: "unset",
            width: "100%",
            minWidth: "unset",
            height: "unset",
            position: "relative",
            [`${InfiniteCollectionItemStyled.PlayIcon}`]: {
              right: 16,
              bottom: 16,
              height: 40,
              width: 40,
              "div > svg": {
                height: 20,
                width: 20,
              },
            },
            [`${AspectRatioContainer}`]: {
              img: {
                borderRadius: 6,
              },
            },
          },
          [`${InfiniteCollectionItemStyled.ImageContainer} ${InfiniteCollectionItemStyled.TextLabel}`]: {
            top: 2,
            borderRadius: "4px 2px 2px",
            [theme.breakpoints.up("sm")]: {
              top: "unset",
            },
          },
          [`${InfiniteCollectionItemStyled.ImageContainer}:has(> ${InfiniteCollectionItemStyled.PlayIcon}) + ${InfiniteCollectionItemStyled.TextContainer} p`]:
            {
              paddingRight: theme.spacing(14),
            },
          [`${InfiniteCollectionItemStyled.TextContainer}`]: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            padding: theme.spacing(4),
            p: {
              width: "100%",
              font: fonts.header4,
              color: colors.neutral0,
            },
          },
        },

        [`${StyledInfiniteCollection.ItemsContainer} > div:first-child`]: {
          flexDirection: "column",
          width: "100%",
          height: "100%",
          span: {
            display: "block",
            width: "100%",
            height: "auto",
            maxWidth: "none",
            div: {
              width: "100%",
            },
          },

          [`${StyledArticleSkeleton.TextContainer}`]: {
            padding: 0,
          },

          "div span": {
            display: "none",
            padding: 0,
          },
        },
      },
    }),
  })
);
