import Image from "next/image";
import { Typography, styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { SCALE_ANIMATION } from "@constants/consts";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const NumericContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
    padding: theme.spacing(5),
    background: colors.neutral10,
    margin: theme.spacing(10, 0),
  },
}));

export const NumericCollectionTitle = styled("p")(({ theme }) => ({
  font: fonts.header3,
  color: colors.neutral90,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(5),
  },
}));

export const NumericGrid = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  gap: theme.spacing(4),

  [theme.breakpoints.between("md", "lg")]: {
    flexDirection: "row",

    [`& > div:nth-of-type(n + 3)`]: {
      [`${AspectRatio}`]: {
        paddingTop: 0,
        height: 190,
      },
    },
  },

  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    width: "calc(100% + 16px)",
  },
}));

export const NumericGridItem = styled("div")(({ theme }) => ({
  ...SCALE_ANIMATION,
  width: "100%",

  [theme.breakpoints.between("md", "lg")]: {
    flexBasis: "calc(50% - 16px)",
    flexGrow: 0,

    [`&:nth-of-type(n + 3)`]: {
      flexBasis: "calc(100%/3 - 16px)",
    },
  },

  [theme.breakpoints.up("lg")]: {
    flexBasis: "calc(20% - 16px)",
    flexGrow: 0,
  },
}));

export const Card = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",

  [theme.breakpoints.up("md")]: {
    display: "block",

    "&:after": {
      content: '""' as string,
      position: "absolute",
      borderRadius: 8,
      inset: 0,
      zIndex: zIndex.base,
      background: gradients.fadeBlackBottomTop,
    },
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  [theme.breakpoints.down("md")]: {
    flex: "0 0 115px",
  },
}));

export const Number = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: zIndex.baseControls,
  backgroundColor: colors.primary,
  borderRadius: 4,
  color: colors.neutral0,
  left: theme.spacing(-1),
  top: theme.spacing(-1),
  height: 24,
  width: 24,
  textAlign: "center",
  font: fonts.header4,
  lineHeight: "24px",

  [theme.breakpoints.up("md")]: {
    height: 32,
    width: 32,
    lineHeight: "32px",
  },
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  borderRadius: 8,
  overflow: "hidden",

  [theme.breakpoints.up("lg")]: {
    paddingBottom: "100%",
  },

  [theme.breakpoints.down("md")]: {
    paddingBottom: "66.25%",
  },
}));

export const NumericCollectionItemImage = styled(Image)(() => ({
  objectFit: "cover",
}));

export const CardTitleWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(1, 0, 0, 3),
  overflow: "hidden",

  [theme.breakpoints.up("md")]: {
    paddingTop: "0",
  },
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  font: fonts.header6,
  color: colors.neutral80,
  overflowWrap: "break-word",

  [theme.breakpoints.up("md")]: {
    WebkitLineClamp: 4,
    color: colors.neutral0,
    position: "absolute",
    zIndex: zIndex.baseControls,
    padding: 0,
    inset: `auto ${theme.spacing(4, 4, 4)}`,
  },
}));
