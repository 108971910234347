import { AudioPlayerType, AudioType } from "@constants/consts";
import { StationSubscription, fetchCurrentShow, fetchCurrentTrack } from "@utils/audio/audioApi";
import { Show, Track } from "@utils/audio/dataModels";
import createScriptElement from "@utils/common/createScriptElement";
import { getConsentData } from "@utils/common/getConsentData";
import getHostConfig from "@utils/common/getHostConfig";
import WebAudioPlayer from "./WebAudioPlayer";

const playerEvents: Array<TDSdkPlayerEvent> = [
  "stream-start",
  "stream-stop",
  "stream-status",
  "track-cue-point",
  "ad-break-cue-point",
  "ad-break-cue-point-complete",
];

const statusToKey: Record<TDSdkState, AudioPlayerEventKey> = {
  LIVE_PAUSE: "pause",
  LIVE_PLAYING: "play",
  LIVE_STOP: "stop",
  LIVE_FAILED: "error",
  LIVE_BUFFERING: "loading",
  LIVE_CONNECTING: "loading",
  LIVE_RECONNECTING: "loading",
  GETTING_STATION_INFORMATION: "loading",
  STREAM_GEO_BLOCKED: "error",
  STREAM_GEO_BLOCKED_ALTERNATE: "error",
  STREAM_GEO_BLOCKED_NO_ALTERNATE: "error",
  STATION_NOT_FOUND: "error",
  PLAY_NOT_ALLOWED: "error",
};

class TritonAudioPlayer extends WebAudioPlayer {
  tritonPlayer: TDSdkPlayer | null;
  currentStation: AudioStationEntry | null = null;
  currentSource: string | null = null;
  streamStatus: string;
  talpaConsent: string;
  distTag: string;
  subscriptions: StationSubscription[] = [];
  type = AudioPlayerType.TRITON;

  onCallback =
    (eventName: TDSdkPlayerEvent) =>
    (event: TDSdkEventParams[TDSdkPlayerEvent]): void => {
      if (eventName === "stream-start") {
        this.isPlaying = true;
      }
      if (eventName === "stream-stop") {
        if (this.type !== AudioPlayerType.VIDEO) this.isPlaying = false;
      }
      if (eventName === "ad-break-cue-point") {
        this.dispatch("adStarted", { details: "Commercial break..." });
      }
      if (eventName === "ad-break-cue-point-complete") {
        this.dispatch("adComplete", { details: this.streamStatus });
      }
      if (eventName === "stream-status") {
        const { data } = event as TDSdkEventParams["stream-status"];
        this.streamStatus = data.status;

        if (statusToKey[data.code] === "play") {
          // If we start the Triton stream muted, TritonSDK just overwrites the said volume
          // That's why we need to set the predefined volume (if it exists) during the stream initialization and not before
          if (this.getVolume() === 0) {
            this.tritonPlayer?.mute();
          }
        }

        this.dispatch(statusToKey[data.code], { details: data.status });
      }
    };

  addEventListeners(): void {
    playerEvents.forEach((eventName) => {
      this.tritonPlayer?.addEventListener(eventName, this.onCallback(eventName));
    });
  }
  removeEventListeners(): void {
    playerEvents.forEach((eventName) => {
      this.tritonPlayer?.removeEventListener(eventName, this.onCallback(eventName));
    });
  }

  private async setup(isHls = false): Promise<void> {
    this.type = AudioPlayerType.TRITON;

    if (typeof TDSdk === "undefined") {
      await createScriptElement("https://sdk.listenlive.co/web/2.9/td-sdk.min.js?_=1638265229969");
    }

    return new Promise<void>(async (resolve, reject) => {
      if (this.tritonPlayer) {
        // Check whether the stream is an HLS one
        if (isHls && this.tritonPlayer.MediaPlayer.hls) {
          return resolve();
        }
        await this.destroyPlayer();
      }

      const { audioDistTag } = getHostConfig();
      this.distTag = audioDistTag;

      const { tcString, talpaConsent } = await getConsentData();
      this.talpaConsent = talpaConsent;

      const tdPlayerConfig: TDSdkConfig = {
        coreModules: [
          {
            id: "MediaPlayer",
            playerId: "td_container",
            techPriority: ["Html5"],
            streamWhileMuted: true,
            debug: true,
            hls: isHls,
            idSync: {
              mount: this.currentSource,
              gdpr: 1, // States whether or not GDPR applies. Always 1
              gdpr_consent: tcString,
            },
            geoTargeting: {
              desktop: {
                isActive: false,
              },
              iOS: {
                isActive: false,
              },
              android: {
                isActive: false,
              },
            },
            plugins: [
              {
                id: "vastAd",
              },
              {
                id: "mediaAd",
              },
              {
                id: "onDemand",
              },
            ],
          },
        ],
        playerReady: () => {
          // Resolve the promise when the player is ready
          if (this.tritonPlayer) {
            this.audioElement = this.tritonPlayer?.MediaElement;
            this.addEventListeners();
            resolve();
          } else {
            reject("No player instance detected");
          }
        },
        configurationError: (error) => {
          // Reject the promise on configuration error
          reject(new Error(`Configuration Error: ${error}`));
        },
        moduleError: (error) => {
          // Reject the promise on module error
          reject(new Error(`Module Error: ${error}`));
        },
      };

      this.tritonPlayer = new TDSdk(tdPlayerConfig);
    });
  }

  async destroyPlayer() {
    this.removeEventListeners();
    this.tritonPlayer?.stop();
    this.tritonPlayer?.destroy();
    await this.waitForStop();
    this.tritonPlayer = null;
  }

  private async waitForStop(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.isPlaying === false || (this.type === AudioPlayerType.VIDEO && this.isPlaying === true)) {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }

  async load(station: AudioStationEntry) {
    this.currentStation = station;
    this.nowPlayingTrack = null;
    if (this.subscriptions.length) {
      this.cleanUpSubscription();
    }

    fetchCurrentTrack(station.slug).then((currentTrack) => {
      if (currentTrack) {
        this.nowPlayingTrack = currentTrack;
        this.dispatch("nowPlaying", currentTrack);
      }
    });

    fetchCurrentShow(station.slug).then((show) => {
      this.nowPlayingShow = show;
      this.dispatch("currentShow", show);
    });

    this.subscriptions.push(
      new StationSubscription(station.slug, AudioType.SHOW, (data) => this.onShowEvent(data as Show))
    );
    this.subscriptions.push(
      new StationSubscription(station.slug, AudioType.TRACK, (data) => this.onTrackEvent(data as Track))
    );
  }

  switchVisualRadio(shouldToggleVideo: boolean): void {
    if (shouldToggleVideo) {
      if (!!this.currentSource) this.destroyPlayer();
      this.type = AudioPlayerType.VIDEO;
    } else {
      this.type = AudioPlayerType.TRITON;
    }

    this.isPlaying = shouldToggleVideo;
  }

  async play(source: string): Promise<void> {
    if (!source) return;
    this.currentSource = source;
    const isHls = source.includes(".m3u8");
    await this.setup(isHls);
    const volume = this.getVolume();
    if (volume) this.tritonPlayer?.setVolume(volume);

    if (isHls) {
      this.tritonPlayer?.MediaPlayer.tech.playStream({
        url: source,
        forceTimeShift: true,
        aSyncCuePoint: {
          active: false,
        },
        isHLS: true,
      });
      return;
    }

    this.tritonPlayer?.play({
      mount: source,
      trackingParameters: { dist: this.distTag, ttag: `talpa_consent:${this.talpaConsent}` },
    });
  }

  async stop(): Promise<void> {
    this.currentSource = null;
    if (this.type === AudioPlayerType.VIDEO) {
      this.dispatch("stop", { details: "" });
      this.type = AudioPlayerType.TRITON;
      this.dispatch("playerType", this.type);
      this.isPlaying = false;
      return;
    }
    if (this.tritonPlayer) {
      this.tritonPlayer.stop();
    }
    await this.waitForStop();
  }

  cleanUpSubscription(): void {
    this.subscriptions.forEach((subscription) => subscription.cleanUp());
    this.subscriptions = [];
  }

  async resume(): Promise<void> {
    // Triton streams can't be resumed
    if (!this.currentSource) return;
    const source = this.currentSource;
    if (this.isPlaying) {
      await this.stop();
    }
    this.play(source);
  }

  pause(): void {
    this.tritonPlayer?.stop();
  }

  mute(): void {
    if (this.tritonPlayer) {
      this.tritonPlayer.mute();
    }
    this.dispatch("mute", { muted: true });
  }

  unmute(): void {
    if (this.tritonPlayer) {
      this.tritonPlayer.unMute();
    }
    this.dispatch("mute", { muted: false });
  }

  volumeChange(volume: number): void {
    if (this.tritonPlayer) {
      this.tritonPlayer.setVolume(volume);
    }
    this.dispatch("playerVolume", { volume });
  }

  onTrackEvent = (track: Track): void => {
    this.nowPlayingTrack = track;
    this.dispatch("nowPlaying", track);
  };

  onShowEvent = (show: Show): void => {
    this.nowPlayingShow = show;
    this.dispatch("currentShow", show);
  };
}
export default TritonAudioPlayer;
