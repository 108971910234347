import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { AudioPlayerStateEnum, AudioPlayerStateType, AudioPlayerType, AudioType, IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import audioPlayerManager from "../../AudioPlayerManager/AudioPlayerManager";
import * as Styled from "./MainPlayerControls.styled";

const MainPlayerControls: React.FC<{
  station: AudioStationEntry;
  playerType: AudioPlayerType;
  playbackState: AudioPlayerStateType;
}> = ({ station, playerType, playbackState }) => {
  const isCurrentStationLoaded = audioPlayerManager.media?.id === station.id;

  const isPlayerActive =
    playbackState === AudioPlayerStateEnum.PLAYING &&
    (isCurrentStationLoaded ||
      (playerType === AudioPlayerType.WEB && audioPlayerManager.media?.type === AudioType.TRACK));

  const handlePlayIconClick = () => {
    if (!isCurrentStationLoaded) {
      audioPlayerManager.load(station);
      audioPlayerManager.start(station.mountSource);
    } else {
      if (playbackState === AudioPlayerStateEnum.STOP || playbackState === AudioPlayerStateEnum.COMPLETED) {
        audioPlayerManager.start(station.mountSource);
      }
      if (playbackState === AudioPlayerStateEnum.PAUSE) {
        audioPlayerManager.resume();
      }
    }
  };

  return (
    <Styled.MainPlayerControlsContainer>
      {isPlayerActive && playerType === AudioPlayerType.WEB && (
        <Styled.StyledIconButton
          icon={IconEnum.PAUSE_THICK}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={() => audioPlayerManager.pause()}
          aria-label="pause"
        />
      )}
      {isPlayerActive && playerType === AudioPlayerType.TRITON && (
        <Styled.StyledIconButton
          icon={IconEnum.STOP_FILLED}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={() => audioPlayerManager.stop()}
          aria-label="stop"
        />
      )}
      {!isPlayerActive && (
        <Styled.StyledIconButton
          icon={
            playbackState === AudioPlayerStateEnum.LOADING && isCurrentStationLoaded
              ? IconEnum.SPINNER
              : IconEnum.PLAY_ICON_FILLED
          }
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={handlePlayIconClick}
          aria-label="play"
        />
      )}
    </Styled.MainPlayerControlsContainer>
  );
};

export default MainPlayerControls;
