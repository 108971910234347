import Image from "next/image";
import { styled } from "@mui/material";
import { colors, fonts } from "@constants/cssVariables";

export const ImageEmbedContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export const ImageEmbedImageContainer = styled("div")(() => ({
  display: "flex",
  maxHeight: 900,
}));

export const ImageEmbedImage = styled(Image)(() => ({
  height: "auto",
  maxWidth: "100%",
  objectFit: "contain",
  objectPosition: "left",
}));

export const ImageEmbedDescription = styled("p")(() => ({
  font: fonts.bodySmall,
  color: colors.neutral40,
}));
