import { useQuery } from "@tanstack/react-query";
import DynamicContent from "@pageContent/DynamicContent";
import InfiniteCollectionSkeleton from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton/InfiniteCollectionSkeleton";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import useFetchLimit from "@utils/pageContent/useFetchLimit";
import * as Styled from "./SearchModalPlaceholder.styled";

const placeholderSearchModalCollectionQueryKey = "placeholder-search-modal-collection";

const SearchModalPlaceholder: React.FC = () => {
  const {
    searchModal,
    designTokens: { articleGrid },
  } = useHostConfig();
  const { placeholderCollectionUrl } = searchModal || {};

  const {
    data: placeholderCollection,
    isLoading,
    error,
  } = useQuery<CategoryBasedCollection>({
    queryKey: [placeholderSearchModalCollectionQueryKey],
    queryFn: () => fetchByEndpoint<CategoryBasedCollection>(placeholderCollectionUrl),
    enabled: !!placeholderCollectionUrl,
  });

  useNoticeError(error, { queryKey: placeholderSearchModalCollectionQueryKey, placeholderCollectionUrl });

  const limit = useFetchLimit(articleGrid.itemFetchLimit);

  if (isLoading) return <InfiniteCollectionSkeleton title="Placeholder Collection" limit={limit} />;

  if (!placeholderCollection) return null;

  return (
    <Styled.SearchContainer>
      <DynamicContent {...placeholderCollection} />
    </Styled.SearchContainer>
  );
};

export default SearchModalPlaceholder;
