import React, { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { BUTTON_SIZE, BUTTON_TYPE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import ButtonContainer from "../ButtonContainer";
import * as Styled from "./Button.styled";

type BaseButtonProps = Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "size">;

interface ButtonProps extends BaseButtonProps {
  children?: ReactNode;
  component?: React.ElementType;
  isLoading?: boolean;
  size?: BUTTON_SIZE;
  variant: VARIANT;
  startIcon?: IconEnum;
  endIcon?: IconEnum;
  disabled?: boolean;
  isNonInteractive?: boolean;
  href?: string;
  fullWidth?: boolean;
  onClick?: React.MouseEventHandler;
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename";
}

const Button: React.FC<ButtonProps> = ({
  children,
  component: Component = "button",
  variant,
  size: size = BUTTON_SIZE.LARGE,
  startIcon,
  isLoading,
  endIcon,
  fullWidth,
  type,
  disabled,
  isNonInteractive,
  ...props
}) => {
  return (
    <ButtonContainer
      as={Component}
      fullWidth={fullWidth}
      buttonType={BUTTON_TYPE.DEFAULT}
      variant={variant}
      size={size}
      type={type}
      disabled={disabled || isLoading}
      isNonInteractive={isNonInteractive}
      {...props}
    >
      {startIcon && <Styled.StyledIcon icon={startIcon} variant={variant} size={size} />}
      {!isLoading && children && <Styled.ButtonText>{children}</Styled.ButtonText>}
      {endIcon && <Styled.StyledIcon icon={endIcon} variant={variant} size={size} />}
    </ButtonContainer>
  );
};

export default Button;

export const PrimaryButton: React.FC<Omit<ButtonProps, "variant">> = ({ children, ...props }) => (
  <Button variant={VARIANT.PRIMARY} {...props}>
    {children}
  </Button>
);
