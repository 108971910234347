import React, { RefObject, useEffect, useRef, useState } from "react";
import Link from "next/link";
import audioPlayerManager from "@audio/AudioPlayerManager/AudioPlayerManager";
import IconSection from "@audio/components/IconSection";
import MainPlayerControls from "@audio/components/MainPlayerControls";
import NowPlaying from "@audio/components/NowPlaying";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import {
  AudioPlayerStateEnum,
  AudioPlayerStateType,
  AudioPlayerType,
  AudioType,
  IconEnum,
  NOW,
  NOW_LIVE,
  ROUTE_PATHS,
  radioLiveVideoContainerIds,
  trackHistory,
} from "@constants/consts";
import { StationSubscription, fetchCurrentShow } from "@utils/audio/audioApi";
import { Show, Track } from "@utils/audio/dataModels";
import trackPlaylistClick from "@utils/audio/trackHistoryClick";
import { useHostConfig } from "@utils/common/getHostConfig";
import { observe, unobserve } from "@utils/common/intersectionObserver";
import useIsomorphicLayoutEffect from "@utils/common/useIsomorphicLayoutEffect";
import * as Styled from "./AudioDashboard.styled";

const AudioDashboard: React.FC<{
  audioDashboardRef: RefObject<HTMLDivElement | null>;
  station: AudioStationEntry;
  isOnStationPage?: boolean;
  initialShow?: Show | null;
}> = ({ audioDashboardRef, station, isOnStationPage, initialShow }) => {
  const { mainStationSlug } = useHostConfig();
  const isOnNonMainStationPage = isOnStationPage && station.slug !== mainStationSlug;

  const [isCurrentStationLoaded, setIsCurrentStationLoaded] = useState(station.id === audioPlayerManager.media?.id);
  const [playbackState, setPlaybackState] = useState<AudioPlayerStateType>(
    isCurrentStationLoaded && audioPlayerManager.getIsPlaying()
      ? AudioPlayerStateEnum.PLAYING
      : AudioPlayerStateEnum.STOP
  );

  const [isDashboardVisible, setIsDashboardVisible] = useState(true);
  const [show, setShow] = useState<Show | null>(() =>
    isCurrentStationLoaded ? audioPlayerManager.getNowPlayingShow() : initialShow || null
  );
  const [isVideoPlaying, isSetVideoPlaying] = useState<AudioPlayerEventParams["playerType"]>(
    audioPlayerManager.getPlayerType() === AudioPlayerType.VIDEO
  );

  useEffect(() => {
    let isPlayerActive = false;
    const handleBeforePlayOrPlay = () => {
      isPlayerActive = true;
    };
    const handleStop = () => {
      isPlayerActive = false;
    };

    audioPlayerManager.on("play", handleBeforePlayOrPlay);
    audioPlayerManager.on("beforePlay", handleBeforePlayOrPlay);
    audioPlayerManager.on("stop", handleStop);

    const handleVisibilityChange = ({ isVisible }: { isVisible: boolean }) => {
      window.eventBus.dispatch("audioDashboard", {
        visible: (isCurrentStationLoaded || isPlayerActive) && isVisible,
      });
      setIsDashboardVisible(isVisible);
    };

    const currentRef = audioDashboardRef.current;

    if (currentRef) {
      observe(currentRef, handleVisibilityChange, "-30px");
    }

    return () => {
      if (currentRef) {
        unobserve(currentRef);
      }
      audioPlayerManager.off("play", handleBeforePlayOrPlay);
      audioPlayerManager.off("stop", handleStop);
      audioPlayerManager.off("beforePlay", handleBeforePlayOrPlay);
    };
  }, [audioDashboardRef, isCurrentStationLoaded]);

  useEffect(() => {
    const handleAudioItem = (audioItem: AudioStationEntry | Track) => {
      setIsCurrentStationLoaded(audioItem.type === AudioType.STATION && station.id === audioItem.id);
    };

    const handleBeforePlay = () => {
      if (audioPlayerManager.media?.id === station.id) setPlaybackState(AudioPlayerStateEnum.LOADING);
    };

    const handlePlay = () => {
      if (isCurrentStationLoaded) setPlaybackState(AudioPlayerStateEnum.PLAYING);
    };

    const handleStop = () => {
      if (isCurrentStationLoaded) setPlaybackState(AudioPlayerStateEnum.STOP);
    };

    const handleAudioCompleted = () => {
      if (isCurrentStationLoaded) setPlaybackState(AudioPlayerStateEnum.COMPLETED);
    };

    const handlePlayerTypeChange = (playerType: AudioPlayerType) => {
      isSetVideoPlaying(playerType === AudioPlayerType.VIDEO);
    };

    audioPlayerManager.on("audioItem", handleAudioItem);
    audioPlayerManager.on("play", handlePlay);
    audioPlayerManager.on("stop", handleStop);
    audioPlayerManager.on("beforePlay", handleBeforePlay);
    audioPlayerManager.on("complete", handleAudioCompleted);
    audioPlayerManager.on("playerType", handlePlayerTypeChange);

    if (!isCurrentStationLoaded) {
      fetchCurrentShow(station.slug).then((show) => {
        setShow(show);
      });
      const showSubscription = new StationSubscription(
        station.slug,
        AudioType.SHOW,
        (data) => data.type === AudioType.SHOW && setShow(data)
      );

      return () => {
        showSubscription.cleanUp();
        audioPlayerManager.off("audioItem", handleAudioItem);
        audioPlayerManager.off("play", handlePlay);
        audioPlayerManager.off("stop", handleStop);
        audioPlayerManager.off("beforePlay", handleBeforePlay);
        audioPlayerManager.off("complete", handleAudioCompleted);
        audioPlayerManager.off("playerType", handlePlayerTypeChange);
      };
    }

    const handlePause = ({}: AudioPlayerEventParams["pause"]) => {
      setPlaybackState(AudioPlayerStateEnum.PAUSE);
    };

    const handleError = ({}: AudioPlayerEventParams["error"]) => {
      setPlaybackState(AudioPlayerStateEnum.FAILED);
    };

    const onNowPlaying = (event: Show | null) => {
      setShow(event);
    };

    audioPlayerManager.on("audioItem", handleAudioItem);
    audioPlayerManager.on("play", handlePlay);
    audioPlayerManager.on("pause", handlePause);

    audioPlayerManager.on("stop", handleStop);
    audioPlayerManager.on("error", handleError);
    audioPlayerManager.on("beforePlay", handleBeforePlay);
    audioPlayerManager.on("playerType", handlePlayerTypeChange);
    audioPlayerManager.on("currentShow", onNowPlaying);

    return () => {
      audioPlayerManager.off("audioItem", handleAudioItem);
      audioPlayerManager.off("play", handlePlay);
      audioPlayerManager.off("pause", handlePause);
      audioPlayerManager.off("stop", handleStop);
      audioPlayerManager.off("error", handleError);
      audioPlayerManager.off("beforePlay", handleBeforePlay);
      audioPlayerManager.off("playerType", handlePlayerTypeChange);
      audioPlayerManager.off("currentShow", onNowPlaying);
    };
  }, [isCurrentStationLoaded, station]);

  useIsomorphicLayoutEffect(
    () => () => {
      // dispatching the event so that VideoPlayerPortal moves to StickyPlayer before unmounting
      window.eventBus.dispatch("audioDashboard", { visible: false });
    },
    []
  );
  if (!station) return null;

  const { title, backgroundUrl, thumbnailUrl, subtitle } = show || station;

  return (
    <Styled.AudioDashboardContainer>
      <Styled.AudioDashboardContent>
        <Styled.IconsContainer>
          <Styled.LiveLabel isVideoPlaying={isVideoPlaying && !isOnNonMainStationPage}>{NOW_LIVE}</Styled.LiveLabel>
          <IconSection station={station} inAudioDashboard={isDashboardVisible} isVideoPlaying={isVideoPlaying} />
        </Styled.IconsContainer>

        <Styled.VideoAndAudioContainer id={radioLiveVideoContainerIds.AUDIO_DASHBOARD}>
          {(!isVideoPlaying || isOnNonMainStationPage) && (
            <>
              <Styled.StationContainer>
                <MainPlayerControls
                  station={station}
                  playbackState={playbackState}
                  playerType={AudioPlayerType.TRITON}
                />
                <Styled.StationInfo>
                  <Styled.StationInfoTitle>{title}</Styled.StationInfoTitle>
                  <Styled.StationInfoDescription>{subtitle}</Styled.StationInfoDescription>
                </Styled.StationInfo>
              </Styled.StationContainer>
              <Styled.TrackContent>
                <Styled.TrackContainer>
                  <Styled.CurrentlyPlayingTrack>
                    <Styled.NowPlayingText>{NOW}</Styled.NowPlayingText>
                    <NowPlaying
                      station={station}
                      playerType={AudioPlayerType.TRITON}
                      isCurrentStationLoaded={isCurrentStationLoaded}
                    />
                  </Styled.CurrentlyPlayingTrack>

                  <Styled.TrackHistoryButton
                    component={Link}
                    href={`${ROUTE_PATHS.PLAYLIST}${station.slug}`}
                    variant={VARIANT.OUTLINE}
                    size={BUTTON_SIZE.SMALL}
                    endIcon={IconEnum.RIGHT_CHEVRON}
                    onClick={() => trackPlaylistClick(station.slug)}
                  >
                    {trackHistory}
                  </Styled.TrackHistoryButton>
                </Styled.TrackContainer>
              </Styled.TrackContent>
            </>
          )}
        </Styled.VideoAndAudioContainer>
      </Styled.AudioDashboardContent>

      <Styled.AudioDashboardBackground isVideoPlaying={isVideoPlaying && !isOnNonMainStationPage}>
        <Styled.ShowImageContainer>
          <Styled.ShowImageWrapper>
            <Styled.AspectRatio aspectRatio="3:2">
              <Styled.ImageBackground />
              <Styled.ShowImage fill src={backgroundUrl || thumbnailUrl} alt={title} />
            </Styled.AspectRatio>
          </Styled.ShowImageWrapper>
        </Styled.ShowImageContainer>
        <Styled.Gradient />
        <Styled.SolidColor />
      </Styled.AudioDashboardBackground>
    </Styled.AudioDashboardContainer>
  );
};

const AudioDashboardWrapper: React.FC<{
  station: AudioStationEntry;
  isOnStationPage?: boolean;
  initialShow?: Show | null;
}> = (props) => {
  const audioDashboardRef = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={audioDashboardRef}>
      <AudioDashboard {...props} audioDashboardRef={audioDashboardRef} />
    </div>
  );
};

export default AudioDashboardWrapper;
