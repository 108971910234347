import { useEffect, useState } from "react";
import { JwMonitor } from "@4tn/webx-analytics";
import VideoPlayer from "@video/VideoPlayer";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import * as Styled from "./InlinePlayer.styled";

export const playButtonTestId = "inline-player-play-button";

interface InlinePlayerProps {
  videoGuid?: string;
  title: string;
  duration: number;
  imageUrl?: string;
  playerLoadedInitially?: boolean;
  isFullWidthXs?: boolean;
  enableFloating?: boolean;
}
const InlinePlayer: React.FC<InlinePlayerProps> = ({
  title,
  videoGuid,
  duration,
  imageUrl,
  playerLoadedInitially,
  isFullWidthXs,
  enableFloating = false,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(playerLoadedInitially || false);

  useEffect(() => {
    setPlayerLoaded(!!playerLoadedInitially);
  }, [playerLoadedInitially]);

  const handleRemove = () => {
    setPlayerLoaded(false);
  };

  const handlePlayClick = () => {
    JwMonitor.setPlayReason("interaction");
    setPlayerLoaded(true);
  };

  if (!videoGuid) return null;

  if (playerLoaded && videoGuid)
    return (
      <VideoPlayer
        guid={videoGuid}
        image={imageUrl}
        onRemove={handleRemove}
        isFullWidthXs={isFullWidthXs}
        enableFloating={enableFloating}
      />
    );

  return (
    <Styled.VideoPlaceholder aspectRatio="16:9" isFullWidthXs={!!isFullWidthXs} onClick={handlePlayClick}>
      <Styled.Gradient isFullWidthXs={!!isFullWidthXs} />
      {imageUrl && (
        <Styled.InlinePlayerImage
          src={imageUrl}
          alt={title}
          fill
          isFullWidthXs={!!isFullWidthXs}
          sizes="(max-width: 1200px) 100vw, 80vw"
        />
      )}
      <Styled.PlayerControlsWrapper>
        <Styled.PlayIcon
          icon={IconEnum.PLAY_ICON_FILLED}
          size={BUTTON_SIZE.MEDIUM}
          variant={VARIANT.PRIMARY}
          color={colors.neutral0}
          data-testid={playButtonTestId}
        />
        <Styled.DurationContainer duration={duration} />
        <Styled.TitleWrapper>
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleWrapper>
      </Styled.PlayerControlsWrapper>
    </Styled.VideoPlaceholder>
  );
};

export default InlinePlayer;
