import React, { FC } from "react";
import Link from "next/link";
import Tealium from "@4tn/webx-analytics";
import AppDownload from "@navigation/AppDownload";
import SocialMedia from "@navigation/SocialMedia";
import Subscription from "@navigation/Subscription";
import { useQuery } from "@tanstack/react-query";
import ErrorBoundary from "@common/ErrorBoundary/ErrorBoundary";
import { NavigationItemType, footerQueryKey, footerTalpaRightsText } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";
import * as Styled from "./Footer.styled";

const trackClick = (eventLabel: string) => {
  Tealium.link({
    event_category: TEALIUM_EVENT_CATEGORY.FOOTER,
    event_name: TEALIUM_EVENT_NAME.FOOTER_CLICK,
    event_label: eventLabel,
  });
};

const SubmenuItem: React.FC<{ submenu: Submenu }> = ({ submenu }) => (
  <Styled.Submenu>
    <Styled.SubmenuTitle>{submenu.title}</Styled.SubmenuTitle>
    {submenu.items.map((item) => (
      <React.Fragment key={item.id}>
        {item.type !== NavigationItemType.SUBMENU && (
          <Link
            href={item.url}
            target={item.target}
            onClick={() => trackClick(item.title)}
            suppressHydrationWarning={item.url.startsWith("#")}
          >
            <Styled.SubmenuItemTitle>{item.title}</Styled.SubmenuItemTitle>
          </Link>
        )}
      </React.Fragment>
    ))}
  </Styled.Submenu>
);

const Footer: FC<{ footer?: Navigation | null }> = ({ footer }) => {
  const { socialMedia, appDownload } = useHostConfig();

  const currentYear = new Date().getFullYear();

  const { data: items, error } = useQuery({
    queryKey: [footerQueryKey],
    queryFn: () => fetchByEndpoint<Navigation>("/api/navigation/footer"),
    refetchOnWindowFocus: false,
    initialData: footer,
    select: (data) => {
      if (!data?.items) return;

      const items = [...data.items];
      while (items.length < 4) {
        items.push({
          slug: "",
          id: `${NavigationItemType.SUBMENU}-${items.length}`,
          title: "",
          type: NavigationItemType.SUBMENU,
          items: [],
        });
      }
      return items;
    },
    enabled: !footer,
  });

  useNoticeError(error, { queryKey: footerQueryKey });

  if (!items) return null;

  return (
    <Styled.FooterWrapper>
      <Styled.ItemsWrapper>
        <Subscription />
        <Styled.SubmenuContainer>
          {items.map((submenu, index) =>
            submenu.type === NavigationItemType.SUBMENU ? (
              <Styled.SubmenuItemWrapper
                key={submenu.id}
                hasSubitems={submenu.items.length > 0}
                isLastItem={index === items.length - 1}
              >
                {submenu.items.length > 0 && <SubmenuItem submenu={submenu} />}
                {items.length - 1 === index && (
                  <>
                    {appDownload && <AppDownload {...appDownload} />}
                    <SocialMedia {...socialMedia} eventCategory={TEALIUM_EVENT_CATEGORY.FOOTER} />
                  </>
                )}
              </Styled.SubmenuItemWrapper>
            ) : null
          )}
        </Styled.SubmenuContainer>
        <Styled.RightsText>&copy; {`${currentYear} ${footerTalpaRightsText}`}</Styled.RightsText>
      </Styled.ItemsWrapper>
    </Styled.FooterWrapper>
  );
};

const SafeFooter: FC<{ footer?: Navigation | null }> = (props) => (
  <ErrorBoundary onError={() => <></>}>
    <Footer {...props} />
  </ErrorBoundary>
);

export default SafeFooter;
