import React, { FC } from "react";
import Tealium from "@4tn/webx-analytics";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { PrimaryButton } from "@common/Buttons";
import * as StyledInfiniteCollection from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";
import * as StyledInfiniteCollectionItem from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem/InfiniteCollectionItem.styled";
import { CollectionSkeleton } from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton/InfiniteCollectionSkeleton";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum, SHOW_MORE_SEARCH_RESULTS } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { useHostConfig } from "@utils/common/getHostConfig";
import * as Styled from "./SearchModalResult.styled";

interface SearchResultItemsProps {
  title: string;
  searchValue?: string;
  items?: PageLink[];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  limit: number;
  fetchNextPage: () => void;
  trackClick: (position: number) => void;
}

const loadMore = (fetchNextPage: () => void) => {
  Tealium.link({
    event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
    event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
    event_label: TEALIUM_EVENT_LABEL.MORE_RESULTS,
  });
  fetchNextPage();
};

const SearchResultItems: FC<SearchResultItemsProps> = ({
  title,
  searchValue,
  items,
  isFetchingNextPage,
  hasNextPage,
  isLoading,
  limit,
  fetchNextPage,
  trackClick,
}) => {
  const {
    designTokens: { isBorderlessDesign },
  } = useHostConfig();

  return (
    <Styled.SearchContainer>
      {searchValue ? (
        <Styled.ResultsTitle>
          {title} <b>&lsquo;{searchValue}&rsquo;</b>
        </Styled.ResultsTitle>
      ) : (
        <Styled.Title>{title}</Styled.Title>
      )}
      <StyledInfiniteCollection.ItemsContainer>
        {items?.map(
          ({ id, url, hasVideo, imageUrl, category, title: linkTitle, dateLabel, contentTypeLabel }, index) => (
            <StyledInfiniteCollectionItem.ItemContainer
              key={id}
              href={url}
              onClick={() => trackClick(index + 1)}
              isBorderlessDesign={isBorderlessDesign}
            >
              <StyledInfiniteCollectionItem.ImageContainer>
                {hasVideo && (
                  <StyledInfiniteCollectionItem.PlayIcon
                    icon={IconEnum.PLAY_ICON_FILLED}
                    size={BUTTON_SIZE.EXTRA_SMALL}
                    variant={VARIANT.PRIMARY}
                    color={colors.neutral0}
                    isNonInteractive
                  />
                )}
                {contentTypeLabel && <StyledInfiniteCollectionItem.TextLabel text={contentTypeLabel} />}
                <AspectRatioContainer aspectRatio="16:9">
                  <StyledInfiniteCollectionItem.ArticleImage
                    src={imageUrl}
                    alt={title}
                    fill
                    sizes="(max-width: 900px) 33vw, (max-width: 1200px) 25vw, 300px"
                  />
                </AspectRatioContainer>
              </StyledInfiniteCollectionItem.ImageContainer>
              <Styled.DetailsContainer>
                <StyledInfiniteCollectionItem.ItemTitle>{linkTitle}</StyledInfiniteCollectionItem.ItemTitle>
                {dateLabel && <Styled.ItemDate>{dateLabel}</Styled.ItemDate>}
                <StyledInfiniteCollectionItem.ItemCategory>{category?.title}</StyledInfiniteCollectionItem.ItemCategory>
              </Styled.DetailsContainer>
            </StyledInfiniteCollectionItem.ItemContainer>
          )
        )}

        {isFetchingNextPage && <CollectionSkeleton title={title} limit={limit} showCategory />}
      </StyledInfiniteCollection.ItemsContainer>
      {hasNextPage && (
        <PrimaryButton fullWidth disabled={isLoading || isFetchingNextPage} onClick={() => loadMore(fetchNextPage)}>
          {SHOW_MORE_SEARCH_RESULTS}
        </PrimaryButton>
      )}
    </Styled.SearchContainer>
  );
};

export default SearchResultItems;
