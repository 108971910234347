import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import * as StyledInfiniteCollection from "../../InfiniteCollection/InfiniteCollection.styled";
import * as StyledArticleSkeleton from "../ArticleSkeleton/ArticleSkeleton.styled";

const lastThreeDirectChilds = "& > div:nth-of-type(n + 3)";
const directDivChild = "& > div";
const articleCategoryField = "& > div > span:last-child";

export const NumericCollectionSkeletonWrapper = styled("div")(({ theme }) => ({
  [articleCategoryField]: {
    display: "none",
  },

  [`${StyledInfiniteCollection.ItemsContainer}`]: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gap: theme.spacing(4),
      gridTemplateColumns: "repeat(12, 1fr)",

      [lastThreeDirectChilds]: {
        gridColumn: "4 span",
      },

      [directDivChild]: {
        gridColumn: "6 span",

        [`${StyledArticleSkeleton.TextContainer}`]: {
          display: "none",
        },
      },
    },

    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(10, 1fr)",

      [directDivChild]: {
        gridColumn: "2 span",
      },

      [lastThreeDirectChilds]: {
        gridColumn: "2 span",

        [`${AspectRatioContainer}`]: {
          paddingBottom: "100%",
        },
      },
    },
  },
}));
