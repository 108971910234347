import Link from "next/link";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./NumericCollection.styled";

const NumericCollection: React.FC<{ title: string; items: PageLink[] }> = ({ title, items }) => {
  return (
    <Styled.NumericContainer>
      <Styled.NumericCollectionTitle>{title}</Styled.NumericCollectionTitle>
      <Styled.NumericGrid>
        {items.map((item, index) => (
          <Styled.NumericGridItem
            key={item.id}
            onClick={() =>
              trackContentClick({
                position: index + 1,
                label: title,
                ...item,
              })
            }
          >
            <Link href={item.url} target={item.target}>
              <Styled.Card>
                <Styled.ImageContainer>
                  <Styled.Number>{index + 1}</Styled.Number>
                  <Styled.AspectRatio aspectRatio="16:9" data-testid="numericCollectionItemAspectRatio">
                    <Styled.NumericCollectionItemImage
                      alt={item.title}
                      src={item.imageUrl}
                      fill
                      sizes="(max-width: 900px) 33vw, (max-width: 1200px) 50vw, 33vw"
                    />
                  </Styled.AspectRatio>
                </Styled.ImageContainer>
                <Styled.CardTitleWrapper>
                  <Styled.CardTitle variant="h6">{item.title}</Styled.CardTitle>
                </Styled.CardTitleWrapper>
              </Styled.Card>
            </Link>
          </Styled.NumericGridItem>
        ))}
      </Styled.NumericGrid>
    </Styled.NumericContainer>
  );
};

export default NumericCollection;
