import InfiniteCollectionSkeleton from "../InfiniteCollectionSkeleton/InfiniteCollectionSkeleton";
import * as Styled from "./NumericCollectionSkeleton.styled";

const NumericCollectionSkeleton: React.FC = () => (
  <Styled.NumericCollectionSkeletonWrapper>
    <InfiniteCollectionSkeleton title="numeric-collection-skeleton" limit={5} />
  </Styled.NumericCollectionSkeletonWrapper>
);

export default NumericCollectionSkeleton;
