import Tealium from "@4tn/webx-analytics";
import { v4 as uuidV4 } from "uuid";
import { Match, ViewType } from "@constants/consts";
import { noticeError, setCustomAttributes } from "./newRelic";

const isClient = typeof window !== "undefined";
const userStorageKey = "feature.user";
export const userQueryParameter = "feature-user";
const maxCacheTime = 120000; // 120sec

export enum FeatureSlugs {
  FIRST_FEATURE = "first-feature",
  ADOBE_DELAY = "adobe-delay",
  ADBLOCK_DETECTION = "adblock-detection",
  DISPLAY_ADS = "display-ads-sdk",
  SUBSCRIPTION_TEXT = "subscription-legal-text",
  TABLE_FILTER = "table-filter",
  TALPA_AUTH = "talpa-auth",
  APP_SMART_BANNER = "app-smart-banner",
  MUTED_AUTOPLAY = "muted-autoplay",
  CONVIVA_POC = "conviva-poc",
  SOCIAL_SHARE_POSITION = "social-share-position",
  CONTACT_STUDIO = "contact-studio",
  TOPLIST_WEB_COMPONENT = "toplist-web-component",
  VIDEO_REPLACEMENT = "video-replacement",
  SHOW_TRACK_CONTEXT_MENU = "show-track-context-menu",
}

export enum SocialShareVariants {
  DEFAULT = "social-share-default",
  NO_TITLE = "social-share-no-title",
  TOP = "social-share-top",
}

export enum VideoReplacementVariants {
  ORIGINAL_VIDEO = "original-video",
  REPLACED_VIDEO = "replaced-video",
}

export class FeatureTooling {
  activeFeatures: IFeature[] = [];
  activeExperiments: {
    featureSlug: string;
    slug: string;
    variant: IVariant | null;
  }[] = [];
  attributes: Record<string, string | boolean | undefined> = {};
  dataFileUrl = "";
  debug = isClient && document.cookie.includes("__FEATURE_DEBUG__");
  experiments: Record<string, string> = {};
  features: IFeature[] = [];
  featuresCache: { [id: string]: boolean } = {};
  featuresTimestamp: number = 0;
  hydrated = false;
  id = "";

  constructor() {
    if (isClient) {
      // hydration
      this.restoreUser();
      try {
        const element = document.getElementById("__FEATURES__");
        if (!element) return;
        const { features, attributes } = JSON.parse(element.innerHTML);
        this.features = features;
        this.attributes = attributes;
        this.updateActiveFeatures();
      } catch (error) {
        this.features = [];
        noticeError(error as Error, { customMessage: "Feature hydration failed" });
      }
    }
  }

  public fetchFeatures = async (dataFileUrl: string) => {
    try {
      const currentTime = Date.now();

      if (
        this.features?.length &&
        currentTime - this.featuresTimestamp < maxCacheTime &&
        dataFileUrl === this.dataFileUrl
      ) {
        this.reset();
        return this.features;
      }

      this.features = await (await fetch(dataFileUrl)).json();
      this.featuresTimestamp = currentTime;
      this.dataFileUrl = dataFileUrl;
      this.reset();
      return this.features;
    } catch (error) {
      noticeError(error as Error, { customMessage: "There was an error while fetching the feature flags." });
    }
    return [];
  };

  public isEnabled = (featureSlug: string): boolean => {
    if (!this.features.length) {
      return false;
    }
    if (this.featuresCache[featureSlug] === undefined) {
      const enabled = this.activeFeatures.some(
        (activeFeature) => activeFeature.slug === featureSlug && !activeFeature.experiment?.enabled
      );
      if (this.hydrated) {
        const experiment = this.activeExperiments.find(
          (activeExperiment) => activeExperiment.featureSlug === featureSlug
        );
        if (experiment?.variant) {
          this.featuresCache[featureSlug] = experiment.variant.featureEnabled;
          this.log(
            `user is using variant '${experiment.variant.variantKey}' for ${featureSlug} | feature is ${
              this.featuresCache[featureSlug] ? "" : "not "
            }enabled`
          );
          return this.featuresCache[featureSlug];
        }
      } else {
        return enabled;
      }

      this.featuresCache[featureSlug] = enabled;

      const feature = this.features.find(({ slug }) => slug === featureSlug);

      this.log(
        feature
          ? `feature ${featureSlug} is ${enabled ? "" : "not "}enabled`
          : `feature ${featureSlug} not found in data file`
      );
    }
    return this.featuresCache[featureSlug];
  };

  public setAttributes = (attributes: FeatureTooling["attributes"]): FeatureTooling["attributes"] => {
    this.attributes = { ...this.attributes, ...attributes };
    return this.attributes;
  };

  public getVariable = <T>(featureSlug: string, variableKey: string, defaultValue: any = null): T | null => {
    const variables = this.getVariables<{ [key: string]: unknown }>(featureSlug);

    if (variables) {
      return typeof variables[variableKey] !== "undefined" ? variables[variableKey] : defaultValue;
    }
    return defaultValue;
  };

  public getVariables = <T>(featureSlug: string): T | null => {
    if (!this.features.length || !this.isEnabled(featureSlug)) {
      return null;
    }

    const experiment = this.activeExperiments.find((activeExperiment) => activeExperiment.featureSlug === featureSlug);
    if (experiment?.variant && !experiment.variant.useDefaultVariables) {
      return experiment.variant.variables;
    }

    const { variables } = this.features.find(({ slug }) => slug === featureSlug) || {};
    return variables;
  };

  public getVariant = (featureSlug: string): string | null => {
    if (!this.features.length || !isClient) {
      return null;
    }
    this.isEnabled(featureSlug);

    const experiment = this.activeExperiments.find((activeExperiment) => activeExperiment.featureSlug === featureSlug);
    return experiment?.variant?.variantKey || null;
  };

  public get isMobile(): boolean {
    return this.attributes.device === ViewType.MOBILE;
  }

  public get isTablet(): boolean {
    return this.attributes.device === ViewType.TABLET;
  }

  public get isDesktop(): boolean {
    return this.attributes.device === ViewType.DESKTOP;
  }

  public reset = (asPath?: string): void => {
    this.hydrated = !!asPath;
    this.featuresCache = {};
    if (asPath) {
      const [currentPath] = asPath.split("?");
      this.attributes.currentPath = currentPath;
    }
    this.updateActiveFeatures();
  };

  private updateActiveFeatures = () => {
    this.activeFeatures = this.features.filter(({ enabled, slug, audience }) => {
      if (enabled && audience) {
        const inAudience = this.isInAudience(audience);
        this.log(`visitor is ${inAudience ? "" : "not "}in audience of feature ${slug}`);
        return inAudience;
      }
      return enabled;
    });

    if (this.hydrated) {
      this.activeExperiments = this.activeFeatures
        .map((feature: IFeature) => {
          if (feature.experiment && feature.experiment.enabled) {
            const experiment = {
              featureSlug: feature.slug,
              slug: `${feature.slug}-test`,
              variant: this.selectVariant(feature),
            };
            return experiment;
          }
          return null;
        })
        .filter(Boolean) as FeatureTooling["activeExperiments"];

      window.eventBus.dispatch("featureReset", this.activeFeatures);

      const featureData = {
        experiment_features: this.activeFeatures
          .map(({ slug }) => slug)
          .filter(this.isEnabled)
          .join("|"),
        experiment_tests: Object.values(this.activeExperiments)
          .map(({ slug }) => slug)
          .join("|"),
        experiment_tests_variants: Object.values(this.activeExperiments)
          .map(({ variant }) => variant?.variantKey || "")
          .join("|"),
      };

      Tealium.setDataLayer(featureData);
      setCustomAttributes(featureData);
    }
  };

  private log = (msg: string): void => {
    if (!this.debug) return;
    console.log("%c [FeatureTooling]", "font-weight:bold", msg);
  };

  private isInAudience = (audience: string): boolean => {
    try {
      const [relation, ...conditions] = JSON.parse(audience) as ["and" | "or", ...TCondition[]];

      const getCurrentValue = (name: string): string | boolean | undefined => {
        if (name === "userId") return this.id || "";
        if (name in this.attributes) return this.attributes[name];
        return "";
      };

      const conditionMet = ({ match, name, value }: TCondition): boolean => {
        const currentValue = getCurrentValue(name);
        if (match === Match.SUBSTRING) return typeof currentValue === "string" && currentValue.includes(value);
        if (match === Match.EXACT) return currentValue === value;
        if (match === Match.DIFFERENT) return currentValue !== value;
        if (match === Match.NOSUBSTRING) return typeof currentValue === "string" && !currentValue.includes(value);
        return false;
      };

      if (relation === "and") {
        return conditions.every(conditionMet);
      }
      return conditions.some(conditionMet);
    } catch (error) {
      this.log((error as any).message);
    }
    return false;
  };

  private selectVariant = (feature: IFeature): IVariant | null => {
    if (!feature.experiment) return null;

    const whitelistedVariant = feature.experiment.variants.find((variant) => variant.whitelist === this.id);
    if (whitelistedVariant) {
      this.log(`user ${this.id} is whitelisted for variant '${whitelistedVariant.variantKey}'`);
      return whitelistedVariant;
    }

    const previousVariant = feature.experiment.variants.find(
      (variant) => variant.variantKey === this.experiments[feature.slug]
    );
    if (previousVariant) {
      this.log(`using previous variant '${previousVariant.variantKey}'`);
      return previousVariant;
    }

    const { variants } = feature.experiment.variants.reduce<{
      start: number;
      variants: { start: number; end: number; variantKey: string }[];
    }>(
      ({ start, variants }, variant) => {
        const end = start + variant.traffic;
        return {
          start: end,
          variants: [...variants, { ...variant, start, end }],
        };
      },
      { start: 0, variants: [] }
    );
    const pointer = Math.random() * 100;
    const { variantKey } = variants.find((variant) => variant.start <= pointer && variant.end >= pointer) || {};
    if (variantKey) {
      this.experiments[feature.slug] = variantKey;
      this.saveUser();
      return feature.experiment.variants.find((variant) => variant.variantKey === variantKey) || null;
    }
    return null;
  };

  private restoreUser = (): void => {
    if (!isClient || typeof localStorage === "undefined") return;

    try {
      const storedUser = localStorage.getItem(userStorageKey);
      const saved = storedUser ? JSON.parse(storedUser) : null;
      if (saved) {
        this.id = saved.id;
        this.experiments = saved.experiments || {};
      }
    } catch (ignore) {}

    const params = new URLSearchParams(window.location.search);
    if (params.has(userQueryParameter)) {
      this.id = params.get(userQueryParameter) || "";
    }
    if (!this.id) this.id = uuidV4();
    this.saveUser();
  };

  private saveUser = (): void => {
    try {
      localStorage.setItem(
        userStorageKey,
        JSON.stringify({
          id: this.id,
          experiments: this.experiments,
        })
      );
    } catch (ignore) {}
  };
}

const featureTooling = new FeatureTooling();

if (isClient) {
  (window as any).__featureTooling = featureTooling;
}

export { default as useFeature } from "./useFeature";

export default featureTooling;
