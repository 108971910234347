import Image from "next/image";
import { styled } from "@mui/material";
import * as StyledIconSection from "@audio/components/IconSection/IconSection.styled";
import * as StyledMainPlayerControls from "@audio/components/MainPlayerControls/MainPlayerControls.styled";
import * as StyledNowPlaying from "@audio/components/NowPlaying/NowPlaying.styled";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { Button } from "@common/Buttons";
import * as StyledButton from "@common/Buttons/Button/Button.styled";
import * as StyledVideoPlayer from "@video/VideoPlayer/VideoPlayer.styled";
import { VIDEO_PORTAL_ID, audioDashboardHeightMd, maxPageContentWidth, transitions } from "@constants/consts";
import { colors, fonts, gradients } from "@constants/cssVariables";

const sharedPseudoIconElementStyles = {
  "&:after": {
    content: "none",
  },
  "&:before": {
    content: "none",
  },
  "&:hover": {
    "&:after": {
      content: "none",
    },
    "&:before": {
      content: "none",
    },
  },
};

export const AudioDashboardContainer = styled("div")(({ theme }) => ({
  transition: transitions.hamburgerMenu,
  background: gradients.gradient04,
  position: "relative",
  display: "flex",
  overflow: "hidden",
  height: 333,
  marginBottom: 32,

  [theme.breakpoints.up("sm")]: {
    height: 378,
  },

  [theme.breakpoints.up("md")]: {
    height: audioDashboardHeightMd,
    marginBottom: 0,
  },

  [theme.breakpoints.up("lg")]: {
    height: 410,
  },
}));

export const AudioDashboardContent = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "flex-start",
  alignItems: "center",
  boxSizing: "border-box",

  width: "100%",
  maxWidth: maxPageContentWidth,
  height: "100%",

  margin: "0 auto",
  padding: theme.spacing(6, 4),

  zIndex: 2,

  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
    padding: theme.spacing(6),
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(10, 20, 6),
    gap: theme.spacing(6),
  },
  [theme.breakpoints.up("lg")]: {
    justifyContent: "space-between",
    flexDirection: "row",
    padding: theme.spacing(10, 24, 6),
  },

  [`${StyledMainPlayerControls.MainPlayerControlsContainer}`]: {
    position: "static",

    [`${StyledMainPlayerControls.StyledIconButton}`]: {
      width: 80,
      height: 80,

      "div > svg": {
        width: 30,
        height: 30,
      },
    },
  },

  [`${StyledIconSection.IconSectionContainer}`]: {
    top: 0,
    justifyContent: "center",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(14),
    },

    [`${StyledIconSection.IconWrapper}`]: {
      svg: {
        "&:hover": {
          color: colors.secondary100,
          transition: "opacity 1s ease-in-out",
        },
      },
    },

    [`${StyledIconSection.VolumeControlsContainer}`]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",

      [`${StyledIconSection.VolumeBarContainer}`]: {
        display: "none",
        position: "absolute",
        top: -75,
        rotate: "-90deg",
        padding: theme.spacing(2.5, 4),
        background: colors.neutral100,
        opacity: "85%",
        borderRadius: "10px",
        transition: "opacity 1s ease-in-out",

        [`${StyledIconSection.VolumeBar}`]: {
          height: 24,
        },
      },

      "&:hover": {
        [theme.breakpoints.up("md")]: {
          [`${StyledIconSection.VolumeBarContainer}`]: {
            display: "flex",
          },
        },
      },
    },

    [`${StyledIconSection.StyledWhatsAppIcon}, ${StyledIconSection.StyledVideoIcon}, ${StyledIconSection.StyledVideoIconFilled}, ${StyledIconSection.VolumeIcon}, ${StyledIconSection.VolumeMutedIcon}, ${StyledIconSection.StyledPopOutIcon}, ${StyledIconSection.StyledPlaylistIcon}`]:
      {
        ...sharedPseudoIconElementStyles,
      },
  },
}));

export const AudioDashboardBackground = styled("div", { shouldForwardProp: (prop) => prop !== "isVideoPlaying" })<{
  isVideoPlaying: boolean;
}>(({ theme, isVideoPlaying }) => ({
  position: "absolute",
  height: "100%",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",

  background: gradients.gradient05,

  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  [`${theme.breakpoints.down("lg")}`]: {
    display: isVideoPlaying ? "none" : "flex",
  },
}));

export const BackgroundContent = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: maxPageContentWidth,
  height: "100%",

  padding: "0 auto",

  display: "flex",
  justifyContent: "flex-end",
  flex: 1,

  margin: theme.spacing(0, 20),

  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-start",
    margin: theme.spacing(0, 24),
  },
}));

export const ShowImageContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: maxPageContentWidth,
  height: 324,

  margin: "0 auto",

  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",

  overflow: "hidden",
  zIndex: 1,

  [theme.breakpoints.up("sm")]: {
    height: 306,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 20),
    justifyContent: "flex-end",
  },

  [theme.breakpoints.up("lg")]: {
    height: 341,
    justifyContent: "flex-start",
    padding: theme.spacing(0, 24),
  },

  [theme.breakpoints.up("xl")]: {
    height: 358,
  },
}));

export const ShowImageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: 450,
  },
  [theme.breakpoints.up("md")]: {
    maxHeight: 355,
    minWidth: 485,
    width: "50%",
  },
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  position: "relative",
  height: "100%",

  display: "flex",
  justifyContent: "center",

  backgroundColor: "transparent",
  zIndex: 1,
  borderRadius: 0,
}));

export const ShowImage = styled(Image)(({ theme }) => ({
  objectFit: "contain",
}));

export const ImageBackground = styled("div")(() => ({
  position: "absolute",
  zIndex: 0,
}));

export const Gradient = styled("div")(({ theme }) => ({
  position: "absolute",
  height: 170,
  bottom: 72,
  left: 0,
  right: 0,
  background: gradients.gradient05,
  zIndex: 5,

  [theme.breakpoints.up("md")]: {
    bottom: 146,
  },

  [theme.breakpoints.up("lg")]: {
    bottom: 69,
    height: 120,
  },

  [theme.breakpoints.up("xl")]: {
    bottom: 52,
  },
}));

export const SolidColor = styled("div")(({ theme }) => ({
  position: "relative",
  height: 72,
  background: colors.primary140,

  [theme.breakpoints.up("md")]: {
    height: 146,
  },

  [theme.breakpoints.up("lg")]: {
    height: 69,
  },

  [theme.breakpoints.up("xl")]: {
    height: 52,
  },
}));

export const TrackContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(6),
  width: "100%",

  [theme.breakpoints.up("lg")]: {
    flexDirection: "row-reverse",
    gap: 0,
    marginTop: "auto",
  },
}));

export const LiveLabel = styled("span", { shouldForwardProp: (prop) => prop !== "isVideoPlaying" })<{
  isVideoPlaying: boolean;
}>(({ theme, isVideoPlaying }) => ({
  maxWidth: 80,
  backgroundColor: colors.secondary100,
  textAlign: "center",
  whiteSpace: "nowrap",
  position: "absolute",
  display: isVideoPlaying ? "none" : "inline-block",
  top: 16,
  left: 16,

  [theme.breakpoints.between("xs", "md")]: {
    padding: theme.spacing(1, 1.5),
  },

  [theme.breakpoints.up("sm")]: {
    top: 24,
    left: 24,
  },

  [theme.breakpoints.up("md")]: {
    top: 40,
    left: "unset",
  },

  [theme.breakpoints.up("lg")]: {
    left: 80,
    position: "static",
    display: "inline-block",
    marginBottom: theme.spacing(5),
  },
}));

export const StationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  width: "100%",
  marginTop: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(6.5),
  },

  [`${StyledMainPlayerControls.MainPlayerControlsContainer}`]: {
    position: "static",

    [`${StyledMainPlayerControls.StyledIconButton}`]: {
      width: 48,
      height: 48,
      "div > svg": {
        width: 24,
        height: 24,
      },

      [theme.breakpoints.up("md")]: {
        width: 80,
        height: 80,
        "div > svg": {
          width: 30,
          height: 30,
        },
      },
    },
  },
}));

export const StationInfo = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

export const StationInfoTitle = styled("div")(({ theme }) => ({
  font: fonts.header4,
  borderRadius: 100,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 570,

  [theme.breakpoints.up("md")]: {
    font: fonts.header3,
    maxWidth: 356,
  },

  [theme.breakpoints.up("xl")]: {
    maxWidth: 455,
  },
}));

export const StationInfoDescription = styled("span")(({ theme }) => ({
  display: "inline-block",
  font: fonts.header5,
  color: colors.neutral0,
  borderRadius: 100,
  flex: "0 1 auto",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: 574,

  [theme.breakpoints.up("lg")]: {
    maxWidth: 346,
  },

  [theme.breakpoints.up("xl")]: {
    maxWidth: 455,
  },
}));

export const TrackContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  gap: theme.spacing(4),
  width: "100%",

  [theme.breakpoints.up("lg")]: {
    flexDirection: "column",
  },
}));

export const CurrentlyPlayingTrack = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("md")]: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    height: 92,
    border: `2px solid ${colors.primary50}`,
    padding: theme.spacing(3, 4),
  },

  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },

  [`${StyledNowPlaying.NowPlayingContainer}`]: {
    maxWidth: "100%",
    gap: 0,

    [`${StyledNowPlaying.NowPlayingImageContainer}`]: {
      position: "relative",
      minWidth: 68,
      height: 68,
      margin: theme.spacing(0, 4, 0, 3),

      [`${StyledNowPlaying.NowPlayingImage}`]: {
        objectFit: "cover",
        borderRadius: 4,
      },
    },

    [`${StyledNowPlaying.NowPlayingTextContainer}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: 0,

      [`${StyledNowPlaying.NowPlayingTitle}`]: {
        font: fonts.header5,
        color: colors.neutral0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 1,
      },

      [`${StyledNowPlaying.NowPlayingSecondaryRowContainer}`]: {
        [`${StyledNowPlaying.NowPlayingLiveLabel}`]: {
          display: "none",
        },

        [`${StyledNowPlaying.NowPlayingSecondaryTitle}`]: {
          font: fonts.bodyMedium,
          color: colors.neutral0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        },
      },
    },
  },
}));

export const NowPlayingText = styled("span")(({ theme }) => ({
  width: 40,
  height: 20,
  textAlign: "right",
  color: colors.neutral0,
}));

export const TrackHistoryButton = styled(Button)(({ theme }) => ({
  font: fonts.buttonSmall,
  borderRadius: 26,
  border: `2px solid ${colors.primary50}`,
  display: "none",

  [`${StyledButton.ButtonText}, div > svg`]: {
    color: colors.primary50,
  },
  "&:hover": {
    border: `2px solid ${colors.primary50}`,

    backgroundColor: colors.primary50,
    [`${StyledButton.ButtonText}, div > svg`]: {
      color: colors.primary140,
    },
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const VideoAndAudioContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(6),

  [theme.breakpoints.down("md")]: {
    width: 360,
  },

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(10),
  },

  [theme.breakpoints.up("lg")]: {
    gap: theme.spacing(21.5),
    width: "50%",
  },

  [`#${VIDEO_PORTAL_ID}`]: {
    position: "relative",
    width: 393,
    paddingBottom: theme.spacing(10.25),

    [`${StyledVideoPlayer.PlayerContainer}`]: {
      boxShadow: "9.38px 9.38px 11.72px 0 rgba(0, 0, 0, 0.25)",
    },

    [theme.breakpoints.between(394, "md")]: {
      paddingBottom: theme.spacing(6),
    },

    [theme.breakpoints.up("sm")]: {
      width: 458,
    },

    [theme.breakpoints.up("md")]: {
      width: 614,
      paddingBottom: 0,
    },

    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },

  ".jwplayer.jw-flag-live": {
    ".jw-icon-display": {
      width: "52px !important",
      height: "52px !important",

      [theme.breakpoints.up("sm")]: {
        width: "80px !important",
        height: "80px !important",
      },

      svg: {
        maskSize: "36px !important",
      },
    },

    ".jw-controls .jw-settings-menu": {
      width: 284,
      height: 192,
      maxWidth: 284,
      maxHeight: 192,
      bottom: 57,
      right: 12,
    },

    ".jw-settings-open .jw-controlbar": {
      display: "block",
    },
  },
}));

export const IconsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: 360,

  [theme.breakpoints.only("md")]: {
    width: "100%",
  },

  [theme.breakpoints.up("lg")]: {
    width: "50%",
    height: "100%",
  },
}));
