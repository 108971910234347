import { FC } from "react";
import { PrimaryButton } from "@common/Buttons";
import * as StyledInfiniteCollection from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";
import ArticleSkeleton from "@pageContent/common/Skeletons/ArticleSkeleton/ArticleSkeleton";
import ButtonSkeleton from "@pageContent/common/Skeletons/ButtonSkeleton/ButtonSkeleton";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton/TextSkeleton";

interface InfiniteCollectionSkeletonProps {
  title: string;
  limit: number;
  showCategory?: boolean;
  showAllText?: string;
  className?: string;
}

type CollectionSkeletonProps = Omit<InfiniteCollectionSkeletonProps, "showAllText" | "className">;

export const CollectionSkeleton: FC<CollectionSkeletonProps> = ({ title, limit, showCategory }) => (
  <>
    {[...Array(limit)].map((_, i) => (
      <ArticleSkeleton showCategory={showCategory} key={`${title}-collection-${i}`} />
    ))}
  </>
);

const InfiniteCollectionSkeleton: FC<InfiniteCollectionSkeletonProps> = ({
  title,
  limit,
  showCategory,
  showAllText,
  className,
}) => {
  return (
    <StyledInfiniteCollection.InfiniteCollectionContainer className={className}>
      <StyledInfiniteCollection.TitleWrapper>
        <TextSkeleton height={[24, 24, 32]} width={[180, 180, 240]} />
        {showAllText && <TextSkeleton height={16} width={[60, 60, 100]} />}
      </StyledInfiniteCollection.TitleWrapper>
      <StyledInfiniteCollection.ItemsContainer>
        <CollectionSkeleton title={title} limit={limit} showCategory={showCategory} />
      </StyledInfiniteCollection.ItemsContainer>
      <ButtonSkeleton>
        <PrimaryButton disabled fullWidth />
      </ButtonSkeleton>
    </StyledInfiniteCollection.InfiniteCollectionContainer>
  );
};

export default InfiniteCollectionSkeleton;
