import ArticleSkeleton from "../ArticleSkeleton";
import ImageSkeleton from "../ImageSkeleton/ImageSkeleton";
import * as Styled from "./GalleryHeaderSkeleton.styled";

const GalleryHeaderSkeleton: React.FC = () => (
  <Styled.GalleryHeaderSkeletonWrapper>
    <ImageSkeleton aspectRatio="16:9" />
    <ArticleSkeleton showCategory />
    <ArticleSkeleton showCategory />
  </Styled.GalleryHeaderSkeletonWrapper>
);

export default GalleryHeaderSkeleton;
