import { useEffect, useState } from "react";
import audioPlayerManager from "@audio/AudioPlayerManager/AudioPlayerManager";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { AudioPlayerType, AudioType, NOW_LIVE, tenSeconds } from "@constants/consts";
import { StationSubscription, fetchCurrentTrack } from "@utils/audio/audioApi";
import { Show, Track } from "@utils/audio/dataModels";
import * as Styled from "./NowPlaying.styled";

export const switchContentTestId = "now-playing-switch-content";
export const imageContainerTestId = "now-palying-image-container";
export const textContainerTestId = "now playing-text-container";

interface NowPlayingContentProps {
  isPlayerTriton: boolean;
  imageUrl: string;
  title: string;
  subtitle: string;
  imageAlt?: string;
}

interface NowPlayingProps {
  station: AudioStationEntry;
  playerType: AudioPlayerEventParams["playerType"];
  isCurrentStationLoaded: boolean;
  alternateNowPlayingDetails?: boolean;
}

const NowPlayingContent: React.FC<NowPlayingContentProps> = ({
  isPlayerTriton,
  imageUrl,
  title,
  subtitle,
  imageAlt,
}) => {
  return (
    <>
      <Styled.NowPlayingImageContainer data-testid={imageContainerTestId}>
        <Styled.NowPlayingImage src={imageUrl} alt={imageAlt || title} fill sizes="(max-width: 900px) 50px, 100px" />
      </Styled.NowPlayingImageContainer>
      <Styled.NowPlayingTextContainer data-testid={textContainerTestId}>
        <Styled.NowPlayingTitle>{title}</Styled.NowPlayingTitle>
        <Styled.NowPlayingSecondaryRowContainer>
          {isPlayerTriton && (
            <Styled.NowPlayingLiveLabel variant={VARIANT.SECONDARY} size={BUTTON_SIZE.EXTRA_SMALL} isNonInteractive>
              {NOW_LIVE}
            </Styled.NowPlayingLiveLabel>
          )}
          <Styled.NowPlayingSecondaryTitle>{subtitle}</Styled.NowPlayingSecondaryTitle>
        </Styled.NowPlayingSecondaryRowContainer>
      </Styled.NowPlayingTextContainer>
    </>
  );
};

const NowPlaying: React.FC<NowPlayingProps> = ({
  station,
  playerType,
  isCurrentStationLoaded,
  alternateNowPlayingDetails = false,
}) => {
  const [track, setTrack] = useState<Track | null>(() =>
    isCurrentStationLoaded ? audioPlayerManager.getNowPlayingTrack() : null
  );
  const [show, setShow] = useState<Show | null>(() =>
    isCurrentStationLoaded ? audioPlayerManager.getNowPlayingShow() : null
  );
  const [showTrackDetails, setShowTrackDetails] = useState(true);
  const isTritonPlayer = playerType === AudioPlayerType.TRITON;

  useEffect(() => {
    if (!isCurrentStationLoaded) {
      fetchCurrentTrack(station.slug).then((data) => {
        setTrack(data);
      });

      const trackSubscription = new StationSubscription(
        station.slug,
        AudioType.TRACK,
        (data) => data.type === AudioType.TRACK && setTrack(data)
      );

      return () => {
        trackSubscription.cleanUp();
      };
    }

    const onNowPlaying = (event: Track | null) => {
      setTrack(event);
    };

    const onCurrentShow = (event: Show | null) => {
      setShow(event);
    };

    audioPlayerManager.on("nowPlaying", onNowPlaying);
    audioPlayerManager.on("currentShow", onCurrentShow);

    return () => {
      audioPlayerManager.off("nowPlaying", onNowPlaying);
      audioPlayerManager.off("currentShow", onCurrentShow);
    };
  }, [station, isCurrentStationLoaded]);

  useEffect(() => {
    if (alternateNowPlayingDetails) {
      const switchInterval = setInterval(() => {
        setShowTrackDetails((prev) => !prev);
      }, tenSeconds);

      return () => clearInterval(switchInterval);
    }
  }, [alternateNowPlayingDetails]);

  if (!alternateNowPlayingDetails || !track) {
    return (
      <Styled.NowPlayingContainer>
        <NowPlayingContent
          isPlayerTriton={isTritonPlayer}
          imageUrl={track?.imageUrl || station.thumbnailUrl}
          imageAlt={track?.imageTitle || station.title}
          title={track?.title || station.title}
          subtitle={track?.artistName || station.subtitle}
        />
      </Styled.NowPlayingContainer>
    );
  }

  return (
    <Styled.NowPlayingContainer>
      <Styled.NowPlayingSwitchContent data-testid={switchContentTestId} showTrackDetails={showTrackDetails}>
        <NowPlayingContent
          isPlayerTriton={isTritonPlayer}
          imageUrl={track.imageUrl || station.thumbnailUrl}
          imageAlt={track.imageTitle}
          title={track.title}
          subtitle={track.artistName}
        />
      </Styled.NowPlayingSwitchContent>
      <Styled.NowPlayingSwitchContent data-testid={switchContentTestId} showTrackDetails={!showTrackDetails}>
        <NowPlayingContent
          isPlayerTriton={isTritonPlayer}
          imageUrl={show?.thumbnailUrl || station.thumbnailUrl}
          imageAlt={station.title}
          title={show?.title || station.title}
          subtitle={show?.subtitle || station.subtitle}
        />
      </Styled.NowPlayingSwitchContent>
    </Styled.NowPlayingContainer>
  );
};

export default NowPlaying;
