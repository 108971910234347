import React from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { Button } from "@common/Buttons";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { RootNode } from "@typings/richText";
import RichText from "../RichText";
import * as Styled from "./TextFrame.styled";

const StationButton = dynamic(() => import("./StationButton"));

interface TextFrameProps {
  title: string;
  text: RootNode;
  target?: "_blank" | "_self";
  buttonText?: string;
  url?: string;
  showTitle?: boolean;
  stationSlug?: string;
}

const TextFrame: React.FC<TextFrameProps> = ({ title, text, target, buttonText, url, showTitle, stationSlug }) => {
  return (
    <Styled.Wrapper>
      {showTitle && <Styled.Title>{title}</Styled.Title>}
      {text && <RichText richText={text} />}
      {stationSlug ? (
        <StationButton slug={stationSlug} />
      ) : (
        buttonText &&
        url && (
          <Button component={Link} variant={VARIANT.PRIMARY} size={BUTTON_SIZE.MEDIUM} target={target} href={url}>
            {buttonText}
          </Button>
        )
      )}
    </Styled.Wrapper>
  );
};

export default TextFrame;
